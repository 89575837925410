import React, { useState } from "react";
import "../Login/logIn_style.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../API/contract";
import { useSearchParams } from "react-router-dom";
import { API } from "../../API/Api";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
export default function Register() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("referrallink"));
  const [mainAccount, setMainAccout] = useState("");
  const [position, setPosition] = useState("");
  let navigate = useNavigate();

  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("Please Connect Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setMainAccout(acc);
        if (acc !== "") {
          let res = await API.post(`/loginWallet`, {
            walletAddress: acc,
            ipaddress: ipaddress,
          });
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            dispatch(
              updateAuth({ Auth360: true, uid: res.data.data.uid_output })
            );
            localStorage.setItem(
              "userData",
              JSON.stringify(res.data.data.uid_output)
            );
            navigate("/dashboard");
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    let response = await axios.get("https://api64.ipify.org/?format=json");
    // console.log("response Geolocation", response.data.IPv4);
    // setIP(response.data.IPv4);
    setMainAccout(acc);
    let ipaddress = response.data.ip;
    if (!acc) {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wallet is locked");
    } else if (acc === "No Wallet" || acc === undefined) {
      toast.error("Please Connect Wallet");
      setconnected("Wallet is locked");
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (position === "" || position === undefined) {
          toast.error("Select Position!!");
        }
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        }
        let res = await API.post("FreeRegistration", {
          sid: sid,
          walletAddress: mainAccount,
          position: position,
        });

        if (res.data.data.result === "Successfull") {
          toast.success("Register Successfull");

          let response = await axios.get("https://api64.ipify.org/?format=json");
          console.log("response Geolocation", response.data.ip);
          // setIP(response.data.IPv4)
          response = response.data.ip;
          let LoginRes = await API.post(`/loginWallet`, {
            walletAddress: mainAccount,
            // ipaddress: response,
          });
          if (LoginRes.data.data.result == "Successfull") {
            toast.success(`Login Successful`);
            dispatch(
              updateAuth({ Auth360: true, uid: LoginRes.data.data.uid_output })
            );
            
            navigate("/dashboard");
          }
        } else {
          toast.error(`${res.data.data.result}`);
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    let intervalId = setInterval(walletConnected, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="Login_bg">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3"></div>
            <div className="col-md-6">
              <div id="root">
                <div id="reg-layout">
                  <div className="Toastify" />
                  <div className="reg-box">
                    <center
                      style={{ marginBottom: 20, marginTop: "5rem" }}
                    ></center>
                    <a href="/">
                      <div className="reg-label" />
                    </a>
                    <div className="reg-content">
                      <div className="space_set" />
                      <div className="subtitle">
                        Access your personal account
                      </div>
                      <span style={{ display: "none" }} id="lock" />
                      <span style={{ display: "none" }} id="network">
                        This is the unknown test network.
                      </span>
                      {connected == ("No Wallet" || "Wrong Network") ? (
                        <>
                          <span
                            id="metamaskConnection"
                            className="metamaskConnection"
                            style={{ color: "red" }}
                          >
                            Wallet is not Connected
                          </span>
                        </>
                      ) : (
                        <span
                          id="metamaskConnection"
                          className="metamaskConnection"
                          style={{ color: "green" }}
                        >
                          {connected}
                        </span>
                      )}

                      <br />
                      <button
                        className="btn btn-primary set_z_index"
                        onClick={() => setregistor_Model(true)}
                      >
                        Register
                      </button>

                      <p className="note">
                        Remember to authorize with the correct Wallet address.
                      </p>
                      <div className="space_set" />
                      <div className="subtitle">View an Account</div>
                      <br />
                      <form>
                        <div className="form-row">
                          {mainAccount ? (
                            <input
                              id="loginid"
                              type="text"
                              value={mainAccount}
                              maxLength={100}
                              oninput="return nameNumValidation(this);"
                              placeholder="Please enter ID or address "
                              className="form-input"
                              // onChange={(e) => {
                              //   setMainAccout(e.target.value);
                              // }}
                              readOnly
                            />
                          ) : (
                            <input
                              id="loginid"
                              type="text"
                              maxLength={100}
                              oninput="return nameNumValidation(this);"
                              placeholder="Please enter ID or address "
                              className="form-input"
                              // onChange={(e) => {
                              //   setMainAccout(e.target.value);
                              // }}
                              readOnly
                            />
                          )}
                          <p className="note">
                            Enter the account id or address
                          </p>
                        </div>
                        <div
                          className="form-row"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <input
                            type="button"
                            className="btn btn-secondary"
                            id="btnlogins"
                            defaultValue="Connect to Wallet"
                            onClick={() => {
                              walletConnected();
                            }}
                          />
                        </div>
                      </form>
                      <p className="loginagain">
                        If you have already registered then{" "}
                        <Link to="/login" style={{ color: "#ff06b7" }}>
                          Login
                        </Link>
                      </p>
                      <div className="footer-section2">
                        {/* Copyright © 2023 360degree. All Rights Reserved. */}
                        <div className="copyright">
                          Copyright © 2023{" "}
                          <a href="https://36t.live/">36t.live</a>. All Rights
                          Reserved.
                        </div>
                      </div>
                      <div
                        id="myModal"
                        className="modal"
                        style={{ display: registor_Model ? "block" : "none" }}
                      >
                        <div className="modal-content-register boxset">
                          <h4>Enter Upline ID</h4>
                          <br />
                          <p>
                            {" "}
                            <input
                              id="txtsid"
                              className="form-control"
                              value={sid}
                              maxLength={50}
                              type="amount"
                              min={0}
                              onChange={(e) => {
                                setSid(
                                  DOMPurify.sanitize(
                                    e.target.value
                                      .replace(/[^0-9]/gi, "")
                                      .substring(0, 7)
                                  )
                                );
                              }}
                            />
                          </p>
                          <br />
                          <h4>Select Position</h4>
                          <br />
                          <p>
                            {" "}
                            <select
                              className="form-control"
                              id="position"
                              value={position}
                              onChange={(e) => setPosition(e.target.value)}
                            >
                              <option value="">Select Position</option>
                              <option value={1}>Left</option>
                              <option value={2}>Right</option>
                            </select>
                          </p>
                          <br />
                          <div className="rerlbtn">
                            <button
                              className=" btn_Model"
                              onClick={() => {
                                registerUser();
                              }}
                            >
                              Register
                            </button>

                            <button
                              className="mx-2 btn_Model"
                              onClick={() => setregistor_Model(false)}
                            >
                              close
                            </button>
                          </div>
                        </div>
                      </div>
                      <div id="myModal123" className="modal1 d-none">
                        <div className="modal-content boxset">
                          <h4>Referral Confirmation</h4>
                          <p>
                            Your Current Referral ID is <label>100</label>
                          </p>
                          <br />
                          <br />
                          <div className="rerlbtn">
                            <button
                              className="btn btn-primary"
                              onclick="register()"
                            >
                              Proceed
                            </button>
                            <a href="/registration" className="btn btn-primary">
                              No,I want to change ID
                            </a>
                          </div>
                        </div>
                      </div>
                      <center>
                        <br />
                        <Link
                          to="/"
                          title="Go To Home"
                          id="gotohome"
                          style={{ color: "#ff06b7" }}
                        >
                          Go To Home
                        </Link>
                      </center>
                      {/*--modal popup end*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
