import React, { useEffect, useState } from "react";
import Sidebar from "../../Dashboard/Sidebar";
import Topbar from "../../Dashboard/Topbar";
import { API } from '../../../API/Api';
import Table from "../../Table/Table";
import Table_Buttons from "../../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../../Redux/slices/userAuth";

export default function RankAdvanceBonus() {
  const [referralApi, setreferralApi] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [listPerpage, setlistPerpage] = useState(10)
  
  
  let uid=useSelector(Userid)

  const LevelDetailsAPI = async () => {
    try {
      let res = await API.post(`/AdvancementIncome`,
      {
          "uid": uid,
          "fdate":"",
          "tdate":""
      })
      res = res.data.data[0];
      setreferralApi([])
      console.log("LevelDetails", res)
      let arr = []
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          income: `${item.income} USD`,
          top_update: `${item.dd} `,
          rankName:item.rankName
        })
      })

      setreferralApi(arr)
    } catch (e) {
      console.log("Level Details API", e)
    }
  }

  useEffect(() => {
    LevelDetailsAPI()
  }, [])
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: 'S.No', accessor: 'sr' },
      { Header: 'UserID', accessor: 'uid' },
      { Header: 'Bonus', accessor: 'income' },
      { Header: 'Rank Name', accessor: 'rankName' },
      { Header: 'Date', accessor: 'top_update' }

    ]
  })

  const indexOfLastPost = currentPage * listPerpage
  const indexOfFirstPage = indexOfLastPost - listPerpage
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost)


  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page " >
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Rank Advancement Bonus</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >

                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />

              </div>
            </div>
          </div>

        
        </div>
      </div>
    </>
  );
}
