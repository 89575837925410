import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import clicko from "../../Assets/images/click.png";
import { useNavigate } from "react-router-dom/dist";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import {
  ContractAddress_Main,
  Abi_Main,
  ContractAddress_USDT,
  Abi_USDT,
} from "../../API/ContractAddressBNB";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";

export default function Deposit() {
  const user = useSelector(Userid);
  // const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  const [BNBBalance, setBNBBalance] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const Calculatevalue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(Totalvalue);
  };

  const reset = async () => {
    setAmount(0);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${user}`);
      responce = responce.data.data[0][0];

      setWallet_Address(responce.Accountaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      // if (
      //   e.response.data.showableMessage ==
      //   "Please login to access this resource"
      // ) {
      //   dispatch(
      //     updateAuth({
      //       wtcAuth: false,
      //       token: null,
      //       userId: null,
      //       ipAddress: null,
      //     })
      //   );
      //   navigate("/");
      // }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const BNB_Balance = async () => {
    try {
      let acc = await loadWeb3();

      let myBalance = 0;
      myBalance = await window.web3.eth.getBalance(acc);
      myBalance = await window.web3.utils.fromWei(myBalance, "ether");

      // console.log("BNB balance",myBalance);

      setBNBBalance(parseFloat(myBalance).toFixed(4));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Deposite_FUND = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSC Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT < Amount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else {
            setloading(true);
            let usdtamountValue = window.web3.utils.toWei(
              Amount.toString(),
              "ether"
            );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Succesful");

              let sellCall = await contract_Main.methods
                .deposit(usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

              let res = await API.post("Deposite_FundByContract", {
                uid: user,
                addreslist: acc,
                usd: Amount,
                txn: sellCall.transactionHash,
                CoinType: "BNB",
              });
              console.log("activation-->", res);
              toast.success("Transaction is succesful");
              if (res.data.success == true) {
                setloading(false);
                toast.success("Activation is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    BNB_Balance();
    GetUserDetails();
  }, []);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ paddingBottom: "10px !important" }}
                >
                  <div className="avlbal">
                    <h2 className="modal-title" id="exampleModal3Label2">
                      Deposit Fund
                    </h2>
                    <h5 className="modal-title" id="exampleModal3Label2">
                      Available USDT :
                      <span id="tokenbalance" style={{ color: "#fff" }}>
                        {USDT}
                      </span>
                      <span style={{ color: "#fff" }}> USDT</span>
                    </h5>
                    <h5 className="modal-title" id="exampleModal3Label2">
                      Available BNB Balance :
                      <span
                        style={{ color: "#fff" }}
                        readOnly=""
                        id="getBalance"
                      >
                        {BNBBalance}
                      </span>
                      <span style={{ color: "#fff" }}> BNB</span>
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="popup_net">
                    <span
                      className="metamaskConnection"
                      style={{ color: "#fff" }}
                    >
                      {connected}
                    </span>
                    {/*-onclick number increase start*/}
                    <div className="addNum">
                      <div className="d-flex trxnumber">
                        <input
                          type="text"
                          readOnly=""
                          id="txtamount"
                          className="form-control"
                          placeholder="Enter USD Amount"
                          value={Amount}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <br />

                      <div className="numbtn">
                        <button
                          onClick={() => {
                            Calculatevalue(5);
                          }}
                        >
                          5 $
                        </button>
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            Calculatevalue(50);
                          }}
                        >
                          50 $
                        </button>
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            Calculatevalue(100);
                          }}
                        >
                          100 $
                        </button>
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            Calculatevalue(250);
                          }}
                        >
                          250 $
                        </button>
                      </div>
                      <div className="numbtn">
                        <button
                          onClick={() => {
                            Calculatevalue(500);
                          }}
                        >
                          500 $
                        </button>
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            Calculatevalue(1000);
                          }}
                        >
                          1000 $
                        </button>
                        <button
                          onClick={() => {
                            setAmount(0);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {/*-onclick number increase end*/}
                    <h5 className="tomenname" style={{ marginTop: "2rem" }} />
                    <div className="row select_network">
                      <div>
                        <div className="col-md-12 col-lg-offset-3">
                          {loading == true ? (
                            <>
                              <Loader />
                            </>
                          ) : (
                            <>
                            <center>
                                <button href="#" onClick={() => Deposite_FUND()}>
                                  <div className="text-center net_box">
                                    <img
                                      src={clicko}
                                      className="img-fluid hxnemg"
                                    />
                                    <h4>Deposit Fund</h4>
                                  </div>
                                </button>
                              </center>
                            </>
                          )}
                        </div>
                        <div className="col-md-12 col-lg-offset-3">
                          <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                            Note: Deposit Amount Should Be Greater Than $ 50.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
