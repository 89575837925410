import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";

export default function DepositHistory() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  // let UserData = localStorage.getItem("userData");
  // let uid=JSON.parse(UserData);
  //  uid = uid.uid_output;
  let uid = useSelector(Userid);
  //
  console.log("Uid", uid);
  const LevelDetailsAPI = async () => {
    try {
      let res = await API.post(`/Deposite_FundByContractHistory`, { uid: uid });
      res = res?.data.data;
      setreferralApi([]);
      // console.log("ActivateHistory", res)
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          uid:item?.uid,
          Date: item?.edate1,
          Transaction_ID: item?.txnid,
          Hash_ID: (
            <a
              href={
                item.CoinType === "BNB"
                  ? `https://bscscan.com/tx/${item.txn}`
                  : `https://polygonscan.com/tx/${item.txn}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              View Txn
            </a>
          ),
          Amount: item?.usd,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    LevelDetailsAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "Transaction_ID", accessor: "Transaction_ID" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Hash", accessor: "Hash_ID" },
      { Header: "Date", accessor: "Date" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Deposit History</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
