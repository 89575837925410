import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import clicko from "../../Assets/images/click.png";
import { useNavigate } from "react-router-dom/dist";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import {
  ContractAddress_Main,
  Abi_Main,
  ContractAddress_USDT,
  Abi_USDT,
} from "../../API/ContractAddressBNB";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";

function Buy() {
  const user = useSelector(Userid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [Amount, setAmount] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [dashData, setdashData] = useState([]);
  let [connected, setconnected] = useState("Wallet is locked");
  let [spiner, setspiner] = useState(false);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [Buy_Rank_name, setBuy_Rank_name] = useState("");
  const [UserType, setUserType] = useState("");
  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${user}`);
      responce = responce.data.data[0][0];
      console.log("dashboardData-->", responce.Buy_Rank_name);
      setBuy_Rank_name(responce.Buy_Rank_name);
      setUserType(responce.Buy_Rank_name);

      setdashData(responce);
      setWallet_Address(responce.Accountaddress);
    } catch (e) {
      console.log("userDetails error", e);
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = val;
    const tvalue = parseFloat(Selvalues);
    setAmount(tvalue);
    setTokenAmount(tvalue * (1 / dashData.BuyRate));
  };

  const BuyToken = async () => {
    if (user === undefined || user === "" || user === null) {
      navigate("/");
    }
    let acc = await loadWeb3();
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
    } else if (acc === "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
    } else {
      if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
        if (Amount !== 0) {
          if (dashData.Net_buyFunds < Amount) {
            toast.error("Insufficient Buy Wallet Balance");
          } else if (UserType === "") {
            toast.error("Select User Type!!!");
          } else {
            setspiner(true);
            console.log("buycoin", "buycoin");
            try {
              let res = await API.post("BuyToken_New", {
                uid: user,
                amount: Amount,
              });
              console.log("buycoin", res);

              if (res.data.data == "Buy Token Successfully") {
                toast.success("Buy Token Successfull");
                navigate("/dashboard");
                setspiner(false);
              } else {
                toast.error(res.data.data);
                setspiner(false);
              }

              setspiner(false);
            } catch (e) {
              console.log("error while calling fuction ", e);
              setspiner(false);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }

    // setloader(false)
  };

  useEffect(() => {
    WalletConnected();
    GetUserDetails();
  }, []);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ paddingBottom: "10px !important" }}
                >
                  <div className="avlbal">
                    <h2 className="modal-title" id="exampleModal3Label2">
                    Buy SHATRI Token
                    </h2>
                    <h5 className="modal-title" id="exampleModal3Label2">
                      Buy Wallet Balance :
                      <span id="tokenbalance" style={{ color: "#fff" }}>
                        {dashData.Net_buyFunds}
                      </span>
                    </h5>
                    <h5 className="modal-title" id="exampleModal3Label2">
                      Buy Token Rate :
                      <span
                        style={{ color: "#fff" }}
                        readOnly=""
                        id="getBalance"
                      >
                        {dashData.BuyRate}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="popup_net">
                    <span
                      className="metamaskConnection"
                      style={{ color: "#fff" }}
                    >
                      {connected}
                    </span>
                    {/*-onclick number increase start*/}
                    <div className="addNum">
                      <div className="d-flex trxnumber">
                        <input
                          type="text"
                          readOnly=""
                          id="txtamount"
                          className="form-control"
                          placeholder="Enter USD Amount"
                          value={Amount}
                          style={{ width: "80%" }}
                        />
                      </div>

                      <br />

                      <div className="numbtn">
                        <button
                          onClick={() => {
                            CalculateValue(10);
                          }}
                        >
                          10 $
                        </button>&nbsp;&nbsp;
                        <button
                          onClick={() => {
                            setAmount(0);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {/*-onclick number increase end*/}
                    <h5 className="tomenname" style={{ marginTop: "2rem" }} />
                    <div className="row select_network">
                      <div>
                        <div className="col-md-12 col-lg-offset-3">
                         <center>
                              <button href="#" onClick={() => BuyToken()}>
                                <div className="text-center net_box">
                                  <img
                                    src={clicko}
                                    className="img-fluid hxnemg"
                                  />
                                  <h4>Buy Token</h4>
                                </div>
                              </button>
                              </center>
                           
                        </div>
                        <div className="col-md-12 col-lg-offset-3">
                          <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                            Note: Buy Token Amount is $ 10.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Buy;
