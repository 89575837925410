import React, { useEffect, useState } from "react";
import "./logIn_style.css";
import { API } from "../../API/Api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import { loadWeb3 } from "../../API/contract";
// import TronWeb from "../../API/contract";
import TronWeb from "tronweb";
import { updateAuth } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
export default function TronLogIn() {
  let navigate = useNavigate();
  const [spinnerload, setspinnerload] = useState(false);
  const [mainAccount, setMainAccout] = useState("");

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const dispatch = useDispatch();

  async function tronConnect() {
    try {
      console.log("initial");
      const mainAccount = await window?.tronWeb?.defaultAddress?.base58;
      console.log("main Account", mainAccount);

      if (mainAccount) {
        setconnected("Wallet is connected");
        console.log("I am having my main account");
        setMainAccout(mainAccount);

        let response = await axios.get("https://api64.ipify.org/?format=json");
        console.log("response Geolocation", response.data.ip);
        // setIP(response.data.IPv4)
        response = response.data.ip;
        let res = await API.post(`/loginWallet`, {
          walletAddress: mainAccount,
          ipaddress: response,
        });
        console.log("UserloginNew", res);
        let UserID = res.data.data;
        console.log("UserloginNew", UserID);
        if (res.data.data.result == "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            updateAuth({ Auth360: true, uid: res.data.data.uid_output })
          );
          // localStorage.setItem("userData", JSON.stringify(UserID));

          navigate("/UpdateWallet");
          //  window.location.reload();
        }
      } else {
        setconnected("Tron wallet is locked");
        const HttpProvider = TronWeb.providers.HttpProvider;
        const fullNode = new HttpProvider("https://api.trongrid.io");
        const solidityNode = new HttpProvider("https://api.trongrid.io");
        const eventServer = "https://api.trongrid.io/";
        const gettronWeb = new TronWeb(fullNode, solidityNode, eventServer);
        setTimeout(() => {
          // getData();
        }, 100);

        toast.warning("Please login or install tron wallet!");
      }
    } catch (error) {
      toast.error(error.message);

      console.log("error", error.message);
    }
  }

  const onSubmitHandler = async (data) => {
    console.log("LoginId", LoginId);
    setspinnerload(true);
    let response = await axios.get("https://geohttps://api64.ipify.org/?format=json");
    console.log("response Geolocation", response.data.ip);
    //setIP(response.data.IPv4)
    response = response.data.ip;
    let res = await API.post(`/loginWallet`, {
      walletAddress: mainAccount,
      ipaddress: response,
    });
    console.log("loginWallet", res);
    let UserID = res.data.data;
    if (res.data.data.result == "Successfull") {
      toast.success(`Login Successful`);
      dispatch(updateAuth({ Auth360: true, uid: res.data.data.uid_output }));
      localStorage.setItem("userData", JSON.stringify(UserID));

      navigate("/UpdateWallet");
      window.location.reload();
    } else {
      toast.error(res.data.data.result);
      setspinnerload(false);
    }
    setspinnerload(false);
  };

  const [account, setAccount] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      tronConnect();
    }, 100);
  }, []);

  return (
    <div className="Login_bg">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div id="root">
                <div id="reg-layout">
                  <div className="Toastify" />
                  <div className="reg-box">
                    <center
                      style={{ marginBottom: 20, marginTop: "5rem" }}
                    ></center>
                    <a href="/">
                      <div className="reg-label" />
                    </a>
                    <br />
                    <div className="reg-content">
                      <div className="subtitle">
                        Access your personal account
                      </div>
                      <span id="lock" style={{ display: "none" }} />
                      <span
                        id="metamaskConnection"
                        style={{
                          color: "green",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {connected}
                      </span>
                      <br />
                      <br />
                      <button
                        className="btn btn-secondary"
                        disabled=""
                        id="vendor"
                        onclick="authorizedlogin()"
                      >
                        Authorized Login
                      </button>
                      <br />
                      <br />
                      <p className="note">
                        Remember to authorize with the correct address.
                      </p>
                      <br />
                      <div className="subtitle">View an Account</div>
                      <br />
                      <form>
                        <div className="form-row">
                          {mainAccount ? (
                            <input
                              id="loginid"
                              type="text"
                              value={mainAccount}
                              maxLength={100}
                              oninput="return nameNumValidation(this);"
                              placeholder="Please enter ID or address "
                              className="form-input"
                              onChange={(e) => {
                                setMainAccout(
                                  DOMPurify.sanitize(
                                    e.target.value.substring(0, 100)
                                  )
                                );
                              }}
                            />
                          ) : (
                            <input
                              id="loginid"
                              type="text"
                              maxLength={100}
                              oninput="return nameNumValidation(this);"
                              placeholder="Please enter ID or address "
                              className="form-input"
                              onChange={(e) => {
                                setMainAccout(
                                  DOMPurify.sanitize(
                                    e.target.value.substring(0, 100)
                                  )
                                );
                              }}
                            />
                          )}

                          <p className="note">
                            Enter the account id or Tron address
                          </p>
                        </div>
                        <div
                          className="form-row "
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* <Link to="/dashboard"> */}
                          <input
                           disabled
                            type="button"
                            className="btn btn-secondary"
                            id="btnlogin"
                            defaultValue="Login"
                            //onClick={() => onSubmitHandler()}
                          />
                          {/* </Link> */}
                        </div>
                      </form>
                      <p className="loginagain">
                        Don’t have an account?{" "}
                        <Link to="/register" style={{ color: "#ff06b7" }}>
                          Register (BSC Chain)
                        </Link>
                      </p>
                      <div className="footer-section2">
                        {/* Copyright © 2023 360degree. All Rights Reserved.{" "} */}
                        <div className="copyright">
                          Copyright © 2023{" "}
                          <a href="https://36t.live/">36t.live</a>. All Rights
                          Reserved.
                        </div>
                        {/* <a
                          href="https://bscscan.com/address/0x6109D3fb364693930d6250e41D1D1393Ebe60dfD#code"
                          rel="noopener noreferrer"
                          title="Tron Smart Contract"
                          style={{ color: "#ff06b7" }}
                        >
                          Tron Smart Contract
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}