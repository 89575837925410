import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clicko from "../../Assets/images/click.png";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../API/Api";

import TronWeb from "tronweb";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";
import Select from "react-select";

export default function UpdateWallet() {
  const [UsdAmount, setUsdAmount] = useState("0");
  const [TokenAmount, setTokenAmount] = useState("0");
  const [Bnb, setBnb] = useState("0");
  let [liveRate, setliveRate] = useState(0);
  let [amount, setamount] = useState(0);
  let [meticmultiplyablevalue, setmeticmultiplyablevalue] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [valueBNB, setvalueBNB] = useState(0);
  let [valueCoin, setvalueCoin] = useState(0);
  let [balanceUSDT, setbalanceUSDT] = useState(0);
  let [TrxBalance, setTrxBalance] = useState(0);
  let [spiner, setspiner] = useState(false);
  let [Sum, setSum] = useState(0);
  const [mainAccount, setMainAccout] = useState("");
  const [dash, setdataArray] = useState([]);
  const [loading, setloading] = useState(false);
  const [f_name, setf_name] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [CountryValue, setCountryValue] = useState("");
  const [WalletAddress, setWalletAddress] = useState("");

  const [selectedCountry, setSelectedCountry] = useState({});
  const [countries, setCountries] = useState([]);

  // console.log("selectedCountry", selectedCountry);
  // console.log("selectedCountry label", selectedCountry.label);
  // console.log("selectedCountry value", selectedCountry.value);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;
  // console.log("Uid", uid);

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setdataArray(responce[0][0]);
      // console.log("profileupdate", responce[0][0].profileupdate);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const GetProfile_API = async () => {
    try {
      let responce = await API?.get(`GetUserProfile?uid=${uid}`);
      responce = responce.data.data[0][0];
      console.log("GetUserProfile", responce);

      setf_name(responce.f_name);
      setMobile(responce.mobile);
      setEmail(responce.email);
      setCountryValue(responce.countryname);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  async function tronConnect() {
    try {
      console.log("initial");
      const mainAccount = await window?.tronWeb?.defaultAddress?.base58;
      console.log("main Account", mainAccount);

      if (mainAccount) {
        console.log("I am having my main account");
        setMainAccout(mainAccount);
        setconnected("Wallet is Connected");
        // let respon = await dispatch(loginAction(mainAccount));
        // const apiResponse = await axios.post("")

        if (mainAccount) {
          //localStorage.setItem("mainAccount", mainAccount);
          console.log("mainAccount", mainAccount);
        } else {
          const HttpProvider = TronWeb.providers.HttpProvider;
          const fullNode = new HttpProvider("https://api.trongrid.io");
          const solidityNode = new HttpProvider("https://api.trongrid.io");
          const eventServer = "https://api.trongrid.io/";
          const gettronWeb = new TronWeb(fullNode, solidityNode, eventServer);

          toast.warning("Please login or install tron wallet!");
        }
      }
    } catch (error) {
      toast.error(error.message);

      console.log("error", error.message);
    }
  }

  const handlef_name = async (value) => {
    setf_name(value.replace(/[^A-Z]/gi, ""));
  };
  const handleEmail = async (value) => {
    setEmail(value);
  };

  const handleMobile = async (value) => {
    setMobile(value.replace(/[^0-9]/gi, ""));
  };

  const UpdateProfile = async () => {
    let acc = mainAccount;
    if (!acc) {
      toast.error("No Wallet");
    } else if (acc != dash.Accountaddress) {
      toast.error("Wallet address and login miss match");
    } else {
      //setspiner(true);

      try {
        let res = await API.post(`/UpdateWalletAddress`, {
          uid: uid,
          AccountAddress: WalletAddress,
        });

        console.log("UpdateUserProfile", res);
        if (res.data.data == "Successful") {
          toast.success("Wallet Address updated successfully!!");
          toast.success("Login with BSC Chain Network!!");
          navigate("/LogIn");
        } else {
          toast.error(`${res.data.data}`);
        }

        // setspiner(false);
      } catch (err) {
        console.log("error while calling fuction profile", err);
        setspiner(false);
      }
    }
  };

  useEffect(() => {
    tronConnect();
    Dashboard_API();
    GetProfile_API();
  }, []);

  // let countryName = selectedCountry.label;
  // let countryvalue = selectedCountry.value;
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
        // setcountryValue()
      });
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Change color based on selection
    }),
  };

  return (
    <>
      <div className="container text-center mx-3">
        <section className="text-center">
          <div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-6">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    
                      <div className="avlbal">
                        <h2 className="modal-title" id="exampleModal3Label2">
                          Update BSC Chain Wallet Address
                        </h2>
                      </div>
                 
                    <div className="modal-body">
                      <div className="popup_net">
                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          Note *{" "}
                          <span className="" style={{ color: "red" }}>
                            {" "}
                            You can update your wallet address only for one time.
                          </span>
                        </span>
                        <br/>
                        <br/>

                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          {connected}
                        </span>

                        <div className="addNum">
                          <div className="d-flex col-md-12">
                          <div className="col-md-4">
                            <span>Wallet Address</span>
                            </div>
                            <div className="col-md-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={WalletAddress}
                              maxLength={50}
                              onChange={(e) => {
                                setWalletAddress(e.target.value);
                              }}
                            />
                            </div>
                          </div>
                        </div>

                        
                        <div className="row">
                         
                            <div className="col-md-12">
                            
                                  <button
                                    href="#"
                                    onClick={() => UpdateProfile()}
                                  >
                                    <div className="text-center net_box ">
                                     
                                      <h4>Update Wallet Address</h4>
                                    </div>
                                  </button>
                             
                            </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
