import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clicko from "../../Assets/images/click.png";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import TronWeb from "tronweb";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import {
  MainAddress,
  Main_Abi,
  TokenAddress,
  Token_Abi,
  Three360_ContractAddreess,
  Three360Token,
} from "../../API/ContractAddress";

export default function ActiveByFund() {
  const [Bnb, setBnb] = useState("0");
  let [liveRate, setliveRate] = useState(0);
  let [amount, setamount] = useState(0);
  let [meticmultiplyablevalue, setmeticmultiplyablevalue] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [valueBNB, setvalueBNB] = useState(0);
  let [valueCoin, setvalueCoin] = useState(0);
  let [balanceUSDT, setbalanceUSDT] = useState(0);
  let [TrxBalance, setTrxBalance] = useState(0);
  let [spiner, setspiner] = useState(false);
  let [Sum, setSum] = useState(0);
  let [type, Settype] = useState(0);
  const [mainAccount, setMainAccout] = useState("");

  const [dash, setdataArray] = useState([]);

  const [loading, setloading] = useState(false);
  let navigate = useNavigate();

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;
  // console.log("Uid", uid);

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setdataArray(responce[0][0]);
      setamount(responce[0][0].NextMatrixPackage);

      let PacakgeAmount = responce[0][0].NextMatrixPackage;
      if (PacakgeAmount > 0) {
        let Res = await API.get(`/getconvertValue?id=${PacakgeAmount}`);
        console.log("RES_BNB", Res);
        //setvalueBNB(Res.data.data[0].ethereum);
        setvalueCoin(Res.data.data[0].ethereum);
      }
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  async function tronConnect() {
    try {
      console.log("initial");
      const mainAccount = await window?.tronWeb?.defaultAddress?.base58;
      console.log("main Account", mainAccount);

      if (mainAccount) {
        console.log("I am having my main account");
        setMainAccout(mainAccount);
        setconnected("Wallet is Connected");

        if (mainAccount) {
          console.log("mainAccount", mainAccount);
        } else {
          const HttpProvider = TronWeb.providers.HttpProvider;
          const fullNode = new HttpProvider("https://api.trongrid.io");
          const solidityNode = new HttpProvider("https://api.trongrid.io");
          const eventServer = "https://api.trongrid.io/";
          const gettronWeb = new TronWeb(fullNode, solidityNode, eventServer);

          toast.warning("Please login or install tron wallet!");
          setconnected("Please login or install tron wallet!");
        }
      }
    } catch (error) {
      toast.error(error.message);

      console.log("error", error.message);
    }
  }

  const TokenBalance = async () => {
    const mainAccount = await window?.tronWeb?.defaultAddress?.base58;
    let acc = await mainAccount;
    console.log("a", acc);

    let tokencontract = await window.tronWeb.contract().at(Three360Token);
    let result = await tokencontract.balanceOf(acc).call();
    result = parseInt(result) / 1000000;
    console.log("Balance Token", result);
    // // result = result.toFixed(1)
    // // setBlnce(result);
    setbalanceUSDT(result);
    let TRX_balance = await window.tronWeb.trx.getBalance(acc);
    console.log("TRX_balance", TRX_balance);
    TRX_balance = TRX_balance / 1000000;
    setTrxBalance(TRX_balance);
  };

  const Activation = async () => {
    let acc = mainAccount;
    console.log("acc", acc);
    if (!acc) {
      toast.error("No Wallet");
    } else if (amount == "0") {
      toast.error("Invalid Package amount");
    } else if (TrxBalance < valueCoin) {
      toast.error("Insufficiant Tron Balance");
    } else if (acc != dash.Accountaddress) {
      toast.error("Wallet address and login miss match");
    }
    // else if (dash.Net_Balance < amount) {
    //   toast.error("Insufficiant Income Balance");
    // }
    else {
      setspiner(true);
      console.log("Data", uid, amount, valueCoin, acc);

      try {
        let resCond = await API.post(`/matrixActivateCondition`, {
          uid: uid,
          amount: amount,
        });

        console.log("matrixActivateCondition", resCond);

        if (resCond.data.data == "0") {
          toast.error("You are not eligible for Matrix Activation");
          setspiner(false);
          return;
        }

        let contract = await window.tronWeb
          .contract()
          .at(Three360_ContractAddreess);

        let sellCall;
        const trxAmount = valueCoin * 1000000; // 21 TRX equivalent in sun
        await contract
          .deposit()
          .send({
            callValue: trxAmount,
          })
          .then(async (output) => {
            console.log("matrixActivateTemp Output:", output, "\n");
            sellCall = await output;
            toast.success("Txn Successful");
          });

        let res = await API.post(`/matrixActivateTemp`, {
          uid: uid,
          walletAddress: acc,
          amount: amount,
          token: valueCoin,
          txn: sellCall,
        });

        console.log("matrixActivateTemp", res);
        if (res.data.data == "waiting") {
          toast.success("Matrix Activation is under process...");
          window.location.reload();
        } else {
          toast.error(`${res.data.data}`);
        }

        // setspiner(false);
      } catch (err) {
        console.log("error while calling fuction matrixActivateTemp", err);
        setspiner(false);
      }
    }
  };

  useEffect(() => {
    tronConnect();
    TokenBalance();
    Dashboard_API();
  }, []);

  return (
    <>
      <div className="container text-center mx-3">
       
        <section className="text-center">
          <div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-6">
              <div className="" style={{ marginTop: "20px" }}>
          <Link className="btn btn-danger btn-lg" to="/dashboard">
            Click to Dashboard
          </Link>
        </div>

                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{ paddingBottom: "10px !important" }}
                    >
                      <div className="avlbal">
                        <h2 className="modal-title" id="exampleModal3Label2">
                          Matrix Activation
                        </h2>
                        {/* <h5 className="modal-title" id="exampleModal3Label2">
                          Available Income Balance :
                          <span id="tokenbalance" style={{ color: "#fff" }}>
                            {dash.Net_Balance}
                          </span>
                          <span style={{ color: "#fff" }}> USD</span>
                        </h5> */}

                        <h5 className="modal-title" id="exampleModal3Label2">
                          Available TRX Balance :
                          <span
                            style={{ color: "#fff" }}
                            readOnly=""
                            id="getBalance"
                          >
                            {TrxBalance}
                          </span>
                          <span style={{ color: "#fff" }}> TRX</span>
                        </h5>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="popup_net">
                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          {connected}
                        </span>
                        {/*-onclick number increase start*/}
                        <div className="addNum">
                          <div className="trxnumber">
                            {/* <select
                              className="form-control active_select"
                              id=""
                              value={amount}
                              onChange={(e) => setamount(e.target.value)}
                            >
                              <option value="">Select Matrix Package</option>
                              <option value={10}>10 USD</option>
                              <option value={50}>50 USD</option>
                              <option value={250}>250 USD</option>
                              <option value={1250}>1250 USD</option>
                              <option value={6250}>6250 USD</option>
                            </select> */}
                            <input
                              type="text"
                              readOnly=""
                              className="form5"
                              placeholder="Package Amount"
                              value={amount}
                            />
                            <span>Package Value</span>
                          </div>
                          <br />
                          <div className="trxnumber">
                            <input
                              type="text"
                              readOnly=""
                              id="txtTokenvalue"
                              className="form5"
                              placeholder="Calculated Tron Value"
                              value={valueCoin}
                            />
                            <span>TRX Value</span>
                          </div>
                          <br />
                        </div>
                        {/*-onclick number increase end*/}
                        <h5
                          className="tomenname"
                          style={{ marginTop: "2rem" }}
                        />
                        <div className="row select_network">
                          <div>
                            <div className="col-md-12 col-lg-offset-3">
                              {spiner ? (
                                <>
                                  <div
                                    class="spinner-border spinnerload"
                                    role="status"
                                  >
                                    <span class="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {dash.packageamount > 0 ? (
                                    <>
                                      <button>
                                        <div className="text-center net_box ">
                                          <img
                                            src={clicko}
                                            className="img-fluid"
                                          />
                                          <h4>Already Activated</h4>
                                        </div>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        href="#"
                                        onClick={() => Activation()}
                                      >
                                        <div className="text-center net_box ">
                                          <img
                                            src={clicko}
                                            className="img-fluid hxnemg"
                                          />
                                          <h4>Activate</h4>
                                        </div>
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
