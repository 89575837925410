import React, { useEffect, useState } from "react";
import Sidebar from "../../Dashboard/Sidebar";
import Topbar from "../../Dashboard/Topbar";
import { API } from "../../../API/Api";
import Table from "../../Table/Table";
import Table_Buttons from "../../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../../Redux/slices/userAuth";

export default function SuperDirectIncome() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;

  let uid=useSelector(Userid)
  // console.log("Uid", uid);

  const SuperDirectIncomeAPI = async () => {
    try {
      let res = await API.post(`/superDirectBonus`, {
        uid: uid,
        fdate: "",
        tdate: "",
      });
      res = res.data.data[0];
      setreferralApi([]);
      console.log("SuperDirectIncome", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.fromid,
          income: `${item.income} USD`,
          package: item.remark,
          top_update: `${item.dd} `,
          f_name:item?.f_name
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("SuperDirectIncome API", e);
    }
  };

  useEffect(() => {
    SuperDirectIncomeAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "UserID", accessor: "uid" },
      { Header: "User Name", accessor: "f_name" },
      // { Header: "Package", accessor: "package" },
      { Header: "Bonus", accessor: "income" },
      { Header: "Date", accessor: "top_update" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Super Direct Bonus</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
