import React from 'react'

function Footer() {
  return (
    <footer className="container-fluid footer">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-12 text-center mx-auto py-3">
              <div className="footer-heading text-center">
                Official Channels
              </div>
            </div>
            <ul className="list-unstyled d-flex align-items-center justify-content-md-center gap-md-5 gap-sm-4 gap-3 footerlist mt-4">
              <li>
                <a className="flex-column" href="https://www.facebook.com/profile.php?id=61559083555453">
                  <span>
                    <img
                      className="w-100 h0199"
                      src="./assets2/image/footer/facebook.png"
                      alt="icon"
                    />
                  </span>
                  Facebook
                </a>
              </li>
              {/* <li>
                <a className="flex-column" href="/">
                  <span>
                    <img
                      className="w-100 h0199"
                      src="./assets2/image/footer/telegram.svg"
                      alt="icon"
                    />
                  </span>
                  Telegram Channel
                </a>
              </li> */}
              <li>
                <a className="flex-column" href="https://www.instagram.com/36t.live">
                  <span>
                    <img
                      className="w-100 h0199"
                      src="./assets2/image/footer/instagram.svg"
                      alt="icon"
                    />
                  </span>
                  Instagram
                </a>
              </li>
              <li>
                <a className="flex-column" href="https://twitter.com/36tlive">
                  <span>
                    <img
                      className="w-100 h0199"
                      src="./assets2/image/footer/twitter.svg"
                      alt="icon"
                    />
                  </span>
                  Twitter
                </a>
              </li>
              <li>
                <a className="flex-column" href="https://www.youtube.com/channel/UCRrx1aaRYMNii0yKGIhAvmg">
                  <span>
                    <img
                      className="w-100 h0199"
                      src="./assets2/image/footer/pngwing 5.svg"
                      alt="icon"
                    />
                  </span>
                  YouTube
                </a>
              </li>
            </ul>
            <div className="row mx-0 align-items-center justify-content-between mt-4">
              <div className="col-md-6 order-2 order-sm-1">
                <div className="copyrighttext">
                  © 36t.club - 2023 - All Right Reserved.
                </div>
              </div>
              <div className="col-md-6 order-sm-2 order-1">
                <div className="bootmlist">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="/">Community Guidlines</a>
                    </li>
                    <li>
                      <a href="/">Terms</a>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer