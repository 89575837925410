import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import clicko from "../../Assets/images/click.png";
import { useNavigate } from "react-router-dom/dist";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import {
  ContractAddress_Main,
  Abi_Main,
  ContractAddress_USDT,
  Abi_USDT,
} from "../../API/ContractAddressBNB";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";

export default function Active() {
  const user = useSelector(Userid);
  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [Amount, setAmount] = useState(0);
  // const [Wallet_Address, setWallet_Address] = useState("");
  const [TopupStatus, setTopupStatus] = useState("0");
  const [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);

  const Calculatevalue = (val) => {
    const Addvalues = val;
    const TotalValue = parseFloat(Addvalues);
    setAmount(TotalValue);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${user}`);
      responce = responce.data.data[0][0];
      // console.log("GetUserDetails", responce);

      // setWallet_Address(responce.btcaddress);
      setTopupStatus(responce.top_up);
      setUSDT(responce.Net_buyFunds);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
    }
  };

  const ActivationUpgrade = async (tstatus) => {
    try {
      if (Amount != 0) {
        if (parseFloat(USDT) < parseFloat(Amount)) {
          toast.error("Insufficient USDT Balance");
          setloading(false);
          return;
        } else {
          setloading(true);
        }

        if (parseFloat(Amount) !== parseFloat(50)) {
          toast.error("You can activate your Id with 50 USDT");
          setloading(false);
          return;
        } else {
          setloading(true);
        }

        if (parseInt(tstatus) === 1) {
          let res = await API.post("IdActivation", {
            uid: user,
            amount: Amount,
          });

          console.log("res-->Activation", res);
          if (res.data.data == "Successfully Activated...!!!") {
            toast.success("Successfully Activated !!");
            window.location.reload();
            setloading(false);
          }
        }

        setloading(false);
      }
    } catch (e) {
      console.log("error while calling fuction sell", e);

      setloading(false);
    }
    // setloader(false)
  };

  useEffect(() => {
    GetUserDetails();
  }, []);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ paddingBottom: "10px !important" }}
                >
                  <div className="avlbal">
                    <h2 className="modal-title" id="exampleModal3Label2">
                      {parseInt(TopupStatus) === 0
                        ? "Activation"
                        : "Already Active"}
                    </h2>
                    <h5 className="modal-title" id="exampleModal3Label2">
                      Available USDT :
                      <span id="tokenbalance" style={{ color: "#fff" }}>
                        {USDT || 0}
                      </span>
                      <span style={{ color: "#fff" }}> USDT</span>
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="popup_net">
                    {/* <span
                      className="metamaskConnection"
                      style={{ color: "#fff" }}
                    >
                      {connected}
                    </span> */}
                    {/*-onclick number increase start*/}
                    <div className="addNum">
                      <div className="d-flex trxnumber">
                        <input
                          type="text"
                          readOnly=""
                          id="txtamount"
                          className="form-control"
                          placeholder="Enter USD Amount"
                          value={Amount}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <br />

                      <div className="numbtn">
                        <button
                          onClick={() => {
                            Calculatevalue(50);
                          }}
                        >
                          50 $
                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          onClick={() => {
                            setAmount(0);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {/*-onclick number increase end*/}
                    <h5 className="tomenname" style={{ marginTop: "2rem" }} />
                    <div className="row select_network">
                      <div>
                        <div className="col-md-12 col-lg-offset-3">
                          {loading == true ? (
                            <>
                              <Loader />
                            </>
                          ) : (
                            <>
                            <center>
                              <button href="#" 
                              onClick={() => {
                                if (parseInt(TopupStatus) === 0) {
                                  ActivationUpgrade(1);
                                } 
                              }}
                              >
                                <div className="text-center net_box">
                                  <img
                                    src={clicko}
                                    className="img-fluid hxnemg"
                                  />
                                  <h4>{parseInt(TopupStatus) === 0
                                    ? "Activate"
                                    : "Already Active"}</h4>
                                </div>
                              </button>
                              </center>
                            </>
                          )}
                        </div>
                        <div className="col-md-12 col-lg-offset-3">
                          <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                          Note: Activation Amount Should Be $ 50.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
