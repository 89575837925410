import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
function Sell_Token_Report() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  // let UserData = localStorage.getItem("userData");
  // let uid=JSON.parse(UserData);
  //  uid = uid.uid_output;
  let uid = useSelector(Userid);
  //
  console.log("Uid", uid);
  const LevelDetailsAPI = async () => {
    try {
      let res = await API.post(`/SellTokenHistory`, 
      {
        uid: uid,
        fdate: "",
        tdate: ""
      });
      console.log("ActivateHistory", res)
      res = res?.data.data;
      setreferralApi([]);
      
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Token: item?.debitCoin,
          Date: item?.edate1,
          Sell_Rate: item?.coin_rate,
          Amount: item?.usdamount,
          Sell_Type: item?.Sell_type,
          txn: item?.txn
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    LevelDetailsAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Date", accessor: "Date" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Token", accessor: "Token" },
      { Header: "Sell Rate", accessor: "Sell_Rate" },      
      { Header: "Sell Type", accessor: "Sell_Type" },
      { Header: "Transaction Id", accessor: "txn" },      
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Sell SHATRI Report</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}

export default Sell_Token_Report;