import "./App.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import LogIn from "./Components/Login/LogIn";
import TronLogIn from "./Components/Login/TronLogIn";
import Register from "./Components/Register/Register";
import Dashboard from "./Components/Dashboard/Dashboard";
import ActivationHistory from "./Components/Pages/ActivationHistory";
import Myreferral from "./Components/Pages/Myreferral";
import Myteam from "./Components/Pages/Myteam";
import Leveldetails from "./Components/Pages/Leveldetails";
import DlBusiness from "./Components/Pages/DlBusiness";
import LevelIncome from "./Components/Pages/All_Income/LevelIncome";
import BinaryIncome from "./Components/Pages/All_Income/BinaryIncome";
import "react-toastify/dist/ReactToastify.css";
import Roi from "./Components/Pages/All_Income/Roi";
import TravelCtoBonus from "./Components/Pages/All_Income/TravelCtoBonus";
import RoiLevel from "./Components/Pages/All_Income/RoiLevel";
import MatrixIncome from "./Components/Pages/All_Income/MatrixIncome";
import NotFound from "./Components/NotFound";
import { ToastContainer, toast } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Incomewithdrawal from "./Components/Withdrawal/Incomewithdrawal";
import WithdrawalHistory from "./Components/Withdrawal/WithdrawalHistory";
import Active from "./Components/Upgrade/Active";
import Active5050 from "./Components/Upgrade/Active5050";
import Wallet_Address from "./Components/Wallet_Address/Wallet_Address";
import Contract_Address from "./Components/Wallet_Address/Contract_Address";
import DailyIncome from "./Components/Pages/All_Income/DailyIncome";
import Comingsoon from "./Components/Dashboard/Comingsoon";
import MatrixDownline from "./Components/Pages/MatrixDownline";
import SuperDirectIncome from "./Components/Pages/All_Income/SuperDirectIncome";
import MatrixTree from "./Components/Pages/MatrixTree";
import CrowdFunding from "./Components/Landing_page/CrowdFunding";
import TronSecurity from "./Components/Landing_page/TronSecurity";
import DefiPlatefrom from "./Components/Landing_page/DefiPlatefrom";
import InvestmentDefi from "./Components/Landing_page/InvestmentDefi";
import ActiveByFund from "./Components/Upgrade/ActiveByFund";
import MatrixActivationHistory from "./Components/Pages/MatrixActivationHistory";
import DirectMatrixIncome from "./Components/Pages/All_Income/DirectMatrixIncome";
import MatrixCtoIncome from "./Components/Pages/All_Income/MatrixCtoIncome";
import PrivateRoutes from "./Redux/PrivateRoutes";
import RankAdvanceBonus from "./Components/Pages/All_Income/RankAdvanceBonus";
import MyTree from "./Components/Tree/MyTree";
import MyRankDetails from "./Components/Pages/MyRankDetails";
import Profile from "./Components/Profile/Profile";
import TravelActive from "./Components/Upgrade/TravelActive";
import TarvelActivateHistory from "./Components/Pages/TravelActivationHistory";
import PoolIncome from "./Components/Pages/All_Income/PoolIncome";
import NewsReport from "./Components/Pages/NewsReport";
import TreeView from "./Components/TreeView/TreeView";
import Deposit from "./Components/Deposit/Deposit";
import DepositHistory from "./Components/Deposit/DepositHistory";
import UpdateWallet from "./Components/UpdateWallet/UpdateWallet";
import { useEffect } from "react";
import Buy from "./Components/Buy/Buy";
import Buy_Token_Report from "./Components/Buy/Buy_Token_Report";
import Stake_Token_Report from "./Components/Buy/Stake_Token_Report";
import Sell_Token from "./Components/SellToken/Sell_Token";
import Sell_Token_Report from "./Components/SellToken/Sell_Token_Report";
import Sell_Rate_Chart from "./Components/Sell_Rate_Chart/Sell_Rate_Chart";

function App() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //     debugger;
  //   });

  //   document.onkeydown = function (e) {
  //     // 73 = Ctrl+I, 85 = Ctrl+U, 123 = F12
  //     if (
  //       (e.ctrlKey && (e.keyCode === 73 || e.keyCode === 85)) ||
  //       e.keyCode === 123
  //     ) {
  //       return false;
  //     }
  //   };
  // }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/LogIn" element={<LogIn />} />
        <Route path="/TronLogIn" element={<TronLogIn />} />
        <Route path="/register" element={<Register />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/activation" element={<Active />} />
          
          <Route exact path="/Buy" element={<Buy />} />
          
          <Route exact path="/Buy_Token_Report" element={<Buy_Token_Report />} />
          <Route exact path="/Stake_Token_Report" element={<Stake_Token_Report />} />
          <Route exact path="/TravelActivation" element={<TravelActive />} />
          <Route exact path="/activation5050" element={<Active5050 />} />
          <Route path="/ActivateHistory" element={<ActivationHistory />} />
          <Route
            path="/TarvelActivateHistory"
            element={<TarvelActivateHistory />}
          />
          <Route path="/Mydirect" element={<Myreferral />} />
          <Route path="/MyRankDetails" element={<MyRankDetails />} />
          <Route path="/NewDownline" element={<Myteam />} />
          <Route path="/LevelDetails" element={<Leveldetails />} />
          <Route path="/DirectLegBusiness" element={<DlBusiness />} />
          <Route path="/LevelIncome" element={<LevelIncome />} />
          <Route path="/BinaryIncome" element={<BinaryIncome />} />
          <Route path="/DirectIncome" element={<Roi />} />
          <Route path="/TreeView" element={<TreeView />} />
          <Route path="/TravelCtoBonus" element={<TravelCtoBonus />} />
          <Route path="/mytree" element={<MyTree />} />

          <Route path="/RankAdvanceBonus" element={<RankAdvanceBonus />} />
          <Route path="/comingsoon" element={<Comingsoon />} />
          <Route path="/Roi_Level_Income" element={<RoiLevel />} />
          <Route exact path="/MatrixIncome" element={<MatrixIncome />} />
          <Route
            exact
            path="/DirectMatrixIncome"
            element={<DirectMatrixIncome />}
          />
          <Route exact path="/MatrixCtoIncome" element={<MatrixCtoIncome />} />
          <Route exact path="/PoolIncome" element={<PoolIncome />} />
          <Route exact path="/Withdrawal" element={<Incomewithdrawal />} />
          <Route exact path="/Crowfunding" element={<CrowdFunding />} />
          <Route exact path="/TronSecurity" element={<TronSecurity />} />
          <Route exact path="/DefiPlatefrom" element={<DefiPlatefrom />} />
          <Route exact path="/InvestmentDefi" element={<InvestmentDefi />} />
          <Route
            exact
            path="/WithdrawalHistory"
            element={<WithdrawalHistory />}
          />
          <Route exact path="/Wallet_Address" element={<Wallet_Address />} />
          <Route
            exact
            path="/Contract_Address"
            element={<Contract_Address />}
          />
          <Route path="/DailyIncome" element={<DailyIncome />} />

          <Route exact path="/MatrixDownline" element={<MatrixDownline />} />
          <Route
            exact
            path="/SuperDirectIncome"
            element={<SuperDirectIncome />}
          />
          <Route exact path="/MatrixTree" element={<MatrixTree />} />
          <Route exact path="/ActiveByFund" element={<ActiveByFund />} />
          <Route
            exact
            path="/MatrixActivationHistory"
            element={<MatrixActivationHistory />}
          />
          <Route exact path="/Profile" element={<Profile />} />
          <Route exact path="/UpdateWallet" element={<UpdateWallet />} />
          <Route exact path="/News" element={<NewsReport />} />
          <Route exact path="/Deposit" element={<Deposit />} />
          <Route exact path="/DepositHistory" element={<DepositHistory />} />
          <Route exact path="/Sell_Token" element={<Sell_Token />} />
          <Route exact path="/Sell_Token_Report" element={<Sell_Token_Report />} />
          <Route exact path="/Sell_Rate_Chart" element={<Sell_Rate_Chart />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      <a
        id="scrollUp"
        href="#top"
        style={{ position: "fixed", zIndex: 2147483647, display: "block" }}
      >
        <AiOutlineArrowUp style={{ fontSize: "25px" }} />
      </a>
    </div>
  );
}

export default App;
