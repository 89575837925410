import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Auth360: false,
  uid: null,
};

export const userAuth = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      console.log("123",action)
      state.Auth360 = action.payload.Auth360;
      state.uid = action.payload.uid;
    },
    resetAuth: (state) => {
      state.Auth360 = false;
      state.uid = null;
      localStorage.clear();
    },
  },
});

export const Userid=(state)=>state.userAuth.uid
export const isAuth=(state)=>state.userAuth.Auth360
  
export const {updateAuth,resetAuth}=userAuth.actions;
export default userAuth.reducer;
