import React from 'react'
import Header from './Header'
import Footer from './Footer'
function CrowdFunding() {
  return (

    <div className="position-relative">
      {/*-------------------------------------------------- Header include Start----------------------------------- */}
    <Header/>

    <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 nowtoJoinSection">
    <div className="container">
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="sectionheading pb-3">Crowdfunding on the Tron Blockchain</div>
          <div className="sub_heading1 py-3">
            <p>
                <b>Smart Contract Facilitated Crowdfunding with Tron</b><br></br>

                Crowdfunding on the Tron blockchain represents a paradigm shift in how entrepreneurs and businesses approach fundraising. Utilizing the Tron blockchain's smart contracts, the process becomes not only more efficient but also more inclusive and transparent.


                <br></br><b>Tron Blockchain and Smart Contracts in Crowdfunding</b><br></br>

                Smart contracts on the Tron platform streamline crowdfunding by automating the investment and payout processes. These self-executing contracts with the terms of the agreement directly written into code are deployed on the Tron blockchain, ensuring that they are executed exactly as programmed without any possibility of downtime, censorship, fraud, or third-party interference.
                  
             </p>
          </div>
          <div className="">
            <h5>Case Scenario Examples of Successful Tron-Based Crowdfunding </h5>
            <p><b>Example A: Supply Chain Management dApp Development</b><br></br>

              <b>Situation -</b> A software development company sought to create a decentralized application (dApp) that would disrupt traditional supply chain management for businesses. They aimed to introduce unparalleled transparency, real-time tracking, and smart contract-based settlements.
                <br></br>
               <b>Crowdfunding Campaign-</b> The company launched a crowdfunding initiative on the Tron blockchain, offering digital tokens to investors. These tokens represented both a stake in the project and the future utility in accessing the dApp's services.
              <br></br>
             <b> Outcome -</b> By achieving specific developmental milestones, the smart contract automatically released funds to the project. This mechanism ensured that investors were only committing funds as the company demonstrated real progress. As the dApp went live, it enabled businesses to optimize their supply chains, and token holders benefited from the increased adoption and token value.</p>
          <br></br>
          <p>
         <h6> Example B: B2B Financial Services Platform</h6>

          <b>Situation - </b>A fintech startup planned to create a platform that leverages blockchain technology to provide secure, decentralized financial services to other businesses.
          <br></br>
          <b>Crowdfunding Campaign -</b> Utilizing Tron's smart contracts, the startup offered a crowdfunding opportunity that was both secure and transparent. Contributors received proprietary tokens, which could later be used on the platform for services or traded.
           <br></br>
          <b>Outcome -</b> The success of the campaign was twofold: it validated the demand for the product in the B2B space and provided the startup with the necessary capital. The smart contract framework allowed for immediate disbursement of funds upon reaching campaign goals, and the continuous transparency of transactions on the Tron ledger bolstered investor confidence.
          </p>
          </div>
          {/* <button className="btn btn-theme2 px-sm-5 px-3 py-2 text-center text-white buttonmain d-flex align-items-center justify-content-center mx-auto">
            Click here to join 36t Community
          </button> */}

          {/* <button onClick={()=>handleCrowdfunding()}>Read More</button> */}
        </div>
        {/* <div className="col-md-4">
          <div className="computer_image p-4">
            <img
              className="w-100 h-100"
              src="./assets2/image/home/computer.png"
              alt="img2"
            />
          </div>
        </div> */}
      </div>
    </div>
  </div> 
  <Footer/>
  </div> )
}

export default CrowdFunding