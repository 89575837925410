import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Dashboard/Sidebar";
import Topbar from "../../Components/Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
export default function WithdrawalHistory() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;


  let uid=useSelector(Userid)
  // console.log("Uid", uid);
  const LevelDetailsAPI = async () => {
    try {
      let res = await API.get(`/withdrawalHistory?uid=${uid}`);
      res = res.data.data;
      setreferralApi([]);
      console.log("WithdrawalHistory", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          amount: item.Request_amount,
          admincharge: `${item.admincharge}`,
          nftcharge: `${item.NFTcharge}`,
          withdrawl: `${item.withdrawl}`,
          statuss: ` ${item.status == 1 ? "Paid" : item.status == 2 ? "Cancel": "Pending"} `,
          top_update: `${item.trans_date} `,
          txn: (
            <a href={`https://tronscan.org/#/transaction/${DOMPurify.sanitize(item.txn)}`} target="_blank">
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    LevelDetailsAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Request Amount", accessor: "amount" },
      { Header: "Admin Charge(10%)", accessor: "admincharge" },
      { Header: "Payable Tron Withdrawal ", accessor: "withdrawl" },
      { Header: "Status", accessor: "statuss" },
      { Header: "Txn", accessor: "txn" },
      { Header: "Paid Date", accessor: "top_update" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="content-page  ">
          <div className="grid grid-1">
            <div className="section-heading">
              <h2 className="panel-title">Withdrawal History</h2>
            </div>
            <div className="content-wrapper contpdset">
              <div className="grid grid-1">
                <div
                  className="box box-default table-wrapper table-responsive"
                  style={{ maxHeight: 500, overflow: "scroll" }}
                >
                  <Table
                    data={[...currentPost]}
                    columns={matching_income.cols}
                  />
                  <Table_Buttons
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={referralApi.length}
                    listPerpage={listPerpage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
