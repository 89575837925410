import React, { useEffect, useState } from "react";
import "./Tree.css";
import UserIcon from "../../Assets/images/user_icon.png";
import { Tooltip } from "antd";
import { API } from "../../API/Api";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
export default function MyTree() {
  const user = useSelector(Userid);

  const [firstTree, setfirstTree] = useState("");
  const [secondtree, setsecondtree] = useState([]);
  const [thirdTreeId, setthirdTreeId] = useState([]);
  const [thirdtree, setthirdtree] = useState({});

  const handleSecondTreeClick = (id) => {
    setthirdTreeId([...thirdTreeId, id]);
    getTreeData3(id);
  };
  const getTreeData1 = async () => {
    try {
      let res = await API.post("/LevelTree", {
        uid: user,
        lvl: 1,
      });
      console.log("ResTreee", res.data.data[0]);
      setfirstTree(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getTreeData2 = async () => {
    try {
      let res = await API.post("/LevelTree", {
        uid: user,
        lvl: 1,
      });
      console.log("ResTreee2", res);
      setsecondtree(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTreeData3 = async (item) => {
    try {
      if (item.downlinecount <= 0) {
        return;
      }
      let res = await API.post("/LevelTree", {
        uid: item.lvluid,
        lvl: 1,
      });
      // console.log("ResTreee3",res.data.data[0]);
      // setthirdtree({...thirdTreeId],[Number(item.lvluid)]:res.data.data[0]});

      setthirdtree((prevThirdtree) => ({
        ...prevThirdtree,
        [item.lvluid]: [...res.data.data[0]],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("123",thirdtree)
  useEffect(() => {
    getTreeData1();
    getTreeData2();
  }, []);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between py_10 mb21">
                    <div className="header-title">
                      <h4 className="card-title">Level Tree</h4>
                    </div>
                  </div>
                  <div className="card-body p-2">
                    <div className="side-app">
                      <div className="main_top">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div className="card shadow">
                              <div className="card-body p-0">
                                <div className="grid-margin">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="tree_view">
                                        <ul>
                                          <li>
                                            <div
                                              className="tree_view_parent"
                                              onmouseover="bigImg(this)"
                                              onmouseout="normalImg(this)"
                                            >
                                              <img
                                                src="assets/images/logo.png"
                                                style={{ height: 70 }}
                                                alt=""
                                              />{" "}
                                              <br />
                                              <Tooltip
                                                title={
                                                  <>
                                                    <div>
                                                      {/* <p>
                                                        Team :{" "}
                                                        {
                                                          firstTree[0].downlinecount
                                                        }
                                                      </p> */}
                                                    </div>
                                                  </>
                                                }
                                              >
                                                <div className="wdg-actions copy_btn_set2">
                                                  <button
                                                    type="button"
                                                    className={`btn ${firstTree.status} btn-sm`}
                                                    data-html="true"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title=""
                                                    style={{
                                                      fontSize: "2rem",
                                                      textAlign: "center",
                                                    }}
                                                    data-original-title=""
                                                  >
                                                    You
                                                  </button>
                                                </div>
                                              </Tooltip>
                                            </div>
                                            <ul>
                                              {secondtree.map(
                                                (items, index) => {
                                                  return (
                                                    <>
                                                      <li
                                                        key={index}
                                                        className="tree_element"
                                                        data-id={637975}
                                                        data-show={1}
                                                      >
                                                        <div
                                                          className="tree_view_parent"
                                                          data-id={637975}
                                                        >
                                                          <img
                                                            src="assets/images/logo.png"
                                                            alt=""
                                                            style={{
                                                              height: 50,
                                                              width: "auto",
                                                            }}
                                                            onClick={() =>
                                                              handleSecondTreeClick(
                                                                items
                                                              )
                                                            }
                                                          />
                                                          <br />
                                                          <Tooltip
                                                            title={
                                                              <>
                                                                <div>
                                                                  <p className="text-white">
                                                                    Name :{" "}
                                                                    {
                                                                      items.name
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Rank :{" "}
                                                                    {
                                                                      items.rankname
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Team :{" "}
                                                                    {
                                                                      items.downlinecount
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </>
                                                            }
                                                          >
                                                            <button
                                                              type="button"
                                                              className={`btn ${items.status} btn-sm`}
                                                              data-html="true"
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title=""
                                                              data-original-title=""
                                                              style={{
                                                                fontSize:
                                                                  "0.9em",
                                                                padding: "3px",
                                                                width: "65px",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {items.lvluid}
                                                            </button>
                                                          </Tooltip>
                                                        </div>
                                                        <ul>
                                                          {thirdtree[
                                                            items?.lvluid
                                                          ]?.map(
                                                            (item, index) => {
                                                              return (
                                                                <>
                                                                  <li
                                                                    key={
                                                                      items.lvluid +
                                                                      index
                                                                    }
                                                                    className="tree_element"
                                                                    data-id={
                                                                      637975
                                                                    }
                                                                    data-show={
                                                                      1
                                                                    }
                                                                  >
                                                                    <div
                                                                      className="tree_view_parent"
                                                                      data-id={
                                                                        637975
                                                                      }
                                                                    >
                                                                      <img
                                                                        src="assets/images/logo.png"
                                                                        alt=""
                                                                        style={{
                                                                          height: 50,
                                                                          width:
                                                                            "auto",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleSecondTreeClick(
                                                                            item
                                                                          )
                                                                        }
                                                                      />
                                                                      <br />
                                                                      <Tooltip
                                                                        title={
                                                                          <>
                                                                            <div>
                                                                              <p className="text-white">
                                                                                Name
                                                                                :{" "}
                                                                                {
                                                                                  item.name
                                                                                }
                                                                              </p>
                                                                              <p className="text-white">
                                                                                Rank
                                                                                :{" "}
                                                                                {
                                                                                  item.rankname
                                                                                }
                                                                              </p>
                                                                              <p className="text-white">
                                                                                Team
                                                                                :{" "}
                                                                                {
                                                                                  item.downlinecount
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        }
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          className={`btn ${item.status} btn-sm`}
                                                                          data-html="true"
                                                                          data-toggle="tooltip"
                                                                          data-placement="top"
                                                                          title=""
                                                                          data-original-title=""
                                                                          style={{
                                                                            fontSize:
                                                                              "0.9em",
                                                                            padding:
                                                                              "3px",
                                                                            width:
                                                                              "65px",
                                                                            textAlign:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          {
                                                                            item.lvluid
                                                                          }
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                    <ul>
                                                                      {thirdtree[
                                                                        item
                                                                          ?.lvluid
                                                                      ]?.map(
                                                                        (
                                                                          item1,
                                                                          index
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <li
                                                                                key={
                                                                                  item1.lvluid +
                                                                                  index
                                                                                }
                                                                                className="tree_element"
                                                                                data-id={
                                                                                  637975
                                                                                }
                                                                                data-show={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className="tree_view_parent"
                                                                                  data-id={
                                                                                    637975
                                                                                  }
                                                                                >
                                                                                  <img
                                                                                    src="assets/images/logo.png"
                                                                                    alt=""
                                                                                    style={{
                                                                                      height: 50,
                                                                                      width:
                                                                                        "auto",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      handleSecondTreeClick(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                  <br />
                                                                                  <Tooltip
                                                                                    title={
                                                                                      <>
                                                                                        <div>
                                                                                          
                                                                                          <p className="text-white">
                                                                                            Name
                                                                                            :{" "}
                                                                                            {
                                                                                              item1.name
                                                                                            }
                                                                                          </p>
                                                                                          <p className="text-white">
                                                                                            Rank 
                                                                                            :{" "}
                                                                                            {
                                                                                              item1.rankname
                                                                                            }
                                                                                          </p>
                                                                                          <p className="text-white">
                                                                                            Team
                                                                                            :{" "}
                                                                                            {
                                                                                              item1.downlinecount
                                                                                            }
                                                                                          </p>
                                                                                        </div>
                                                                                      </>
                                                                                    }
                                                                                  >
                                                                                    <button
                                                                                      type="button"
                                                                                      className={`btn ${item1.status} btn-sm`}
                                                                                      data-html="true"
                                                                                      data-toggle="tooltip"
                                                                                      data-placement="top"
                                                                                      title=""
                                                                                      data-original-title=""
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "0.9em",
                                                                                        padding:
                                                                                          "3px",
                                                                                        width:
                                                                                          "65px",
                                                                                        textAlign:
                                                                                          "center",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        item1.lvluid
                                                                                      }
                                                                                    </button>
                                                                                  </Tooltip>
                                                                                </div>

                                                                                <ul>
                                                                                  {thirdtree[
                                                                                    item1
                                                                                      ?.lvluid
                                                                                  ]?.map(
                                                                                    (
                                                                                      item2,
                                                                                      index
                                                                                    ) => {
                                                                                      return (
                                                                                        <>
                                                                                          <li
                                                                                            key={
                                                                                              item2.lvluid +
                                                                                              index
                                                                                            }
                                                                                            className="tree_element"
                                                                                            data-id={
                                                                                              637975
                                                                                            }
                                                                                            data-show={
                                                                                              1
                                                                                            }
                                                                                          >
                                                                                            <div
                                                                                              className="tree_view_parent"
                                                                                              data-id={
                                                                                                637975
                                                                                              }
                                                                                            >
                                                                                              <img
                                                                                                src="assets/images/logo.png"
                                                                                                alt=""
                                                                                                style={{
                                                                                                  height: 50,
                                                                                                  width:
                                                                                                    "auto",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                  handleSecondTreeClick(
                                                                                                    item
                                                                                                  )
                                                                                                }
                                                                                              />
                                                                                              <br />
                                                                                              <Tooltip
                                                                                                title={
                                                                                                  <>
                                                                                                    <div>

                                                                                                      <p className="text-white">
                                                                                                        Name
                                                                                                        :{" "}
                                                                                                        {
                                                                                                          item2.name
                                                                                                        }
                                                                                                      </p>
                                                                                                      <p className="text-white">
                                                                                                        Rank
                                                                                                        :{" "}
                                                                                                        {
                                                                                                          item2.rankname
                                                                                                        }
                                                                                                      </p>
                                                                                                      <p className="text-white">
                                                                                                        Team
                                                                                                        :{" "}
                                                                                                        {
                                                                                                          item2.downlinecount
                                                                                                        }
                                                                                                      </p>
                                                                                                    </div>
                                                                                                  </>
                                                                                                }
                                                                                              >
                                                                                                <button
                                                                                                  type="button"
                                                                                                  className={`btn ${item2.status} btn-sm`}
                                                                                                  data-html="true"
                                                                                                  data-toggle="tooltip"
                                                                                                  data-placement="top"
                                                                                                  title=""
                                                                                                  data-original-title=""
                                                                                                  style={{
                                                                                                    fontSize:
                                                                                                      "0.9em",
                                                                                                    padding:
                                                                                                      "3px",
                                                                                                    width:
                                                                                                      "65px",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                  }}
                                                                                                >
                                                                                                  {
                                                                                                    item2.lvluid
                                                                                                  }
                                                                                                </button>
                                                                                              </Tooltip>
                                                                                            </div>
                                                                                          </li>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                </ul>
                                                                              </li>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </ul>
                                                                  </li>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
