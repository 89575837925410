import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useRef } from "react";
import { API } from "../../API/Api";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

export default function Dashboard() {
  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;
  let uid = useSelector(Userid);
  console.log("Uid", uid);
  const location = useLocation();
  const hostSiteUrl = window.location.origin;
  const CopyAddressRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [IPAddress, setIPAddress] = useState("");

  const CopyAddressHandler = () => {
    const text = CopyAddressRef.current.value;
    navigator.clipboard.writeText(text);
  };

  const [dash, setdataArray] = useState([]);
  const [user, setUser] = useState(100);
  const [news, setNews] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.post(`dashboard_new?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setdataArray(responce[0][0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const News_API = async () => {
    try {
      let responce = await API?.post(`news`);
      console.log("news response==>", responce);
      responce = responce.data.data[0][0];
      console.log("news", responce.content);
      if (responce) {
        setNews(responce.content);
      }
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const IpAddress = async () => {
    try {
      let response = await axios.get("https://api64.ipify.org/?format=json");
      response = response.data.ip;
      console.log("response Geolocation", response);
      setIPAddress(response);
    } catch (error) {}
  };

  useEffect(() => {
    Dashboard_API();
    IpAddress();
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    News_API();
  }, [copied]);


  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(5);
  const BuySellChartAPI = async () => {
    try {
      let res = await API.get(`/buysellrequestHistory`);
      res = res?.data.data;
      setreferralApi([]);
      console.log("ActivateHistory--???", res)
      let arr = [];
      res[0].forEach((item, index) => {
        arr.push({
          Number: item.row,
          Buy_Rate: item?.BuyRate,
          Sell_Rate: item?.sellRate,
          USD: item?.USD,
          Token_No: item?.TotalCoin,
          Remark: (
            <button
              className={`btn btn-theme1 text-white px-3 py-1 ${
                item.Remark == "Buy" ? "bgmain" : "sellmain"
              } shadow-none fs-12`}>
              {item?.Remark}
            </button>
          ),
          Txn: item?.Txn,
          Date: item?.dd,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };
  useEffect(() => {
    BuySellChartAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Buy Rate", accessor: "Buy_Rate" },
      { Header: "Sell Rate", accessor: "Sell_Rate" },
      { Header: "USD", accessor: "USD" },      
      { Header: "SHATRI Token", accessor: "Token_No" },
      { Header: "Remark", accessor: "Remark" },
      { Header: "Txn", accessor: "Txn" },
      { Header: "Date", accessor: "Date" },
    ],
  });
  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Topbar />
      </div>
      <div>
        <div className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="bg-label-primary rounded-3 text-center mb-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mainss">
                                <div className="text-center">
                                  <div className="text-center ">
                                    <h4 className="mb-0 text-nowrap">
                                      {dash.TotalInvest} USD
                                    </h4>
                                    <h6 className="userskilTit d-flex justify-content-center">
                                      MY PACKAGE
                                    </h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3">
                          <div className="col-12">
                            <div className="userskil">
                              <div className="userskilOne">
                                <div className="userskilFill text-white">
                                  <span className="text-white">
                                    Member ID:- &nbsp;
                                    {dash.userid} {"(" + dash.f_name + ")"}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="userskilOne">
                                            <div className="userskilFill text-white"><span className="text-white"> <Link to="/activation5050" style={{ color: "white" }}>
                                            {dash.packageamount > 0 ? (
                                              <>Upgrade 50 50</>
                                            ) : (
                                              <>Active 50 50</>
                                            )}
                                          </Link></span>
                                          </div>
                                        </div> */}
                              <br></br>
                              {/* <div className="userskilOne">
                                <div className="text-center">
                                  <p className="mb-2">
                                    Matrix Investment :<br />{" "}
                                    {dash.TotalPackageMatrix} USD
                                  </p>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <Link to="/MyRankDetails">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <small className="d-block mb-1 colors card-heading ims">
                              Current Date & Time
                            </small>
                            <i className="fa fa-users ims"></i>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="mb-0 pt-1 text-nowrap">
                                {" "}
                                {dash.currentdatetime}{" "}
                              </h5>
                            </div>
                            <div className="col-4">
                              <div className="divider divider-vertical"></div>
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent="75"
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link to="/MyRankDetails">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <small className="d-block mb-1 colors card-heading ims">
                              My Rank
                            </small>
                            <i className="fa fa-users ims"></i>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="mb-0 pt-1 text-nowrap">
                                {" "}
                                {dash.rankname}{" "}
                              </h5>
                            </div>
                            <div className="col-4">
                              <div className="divider divider-vertical"></div>
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent="75"
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link to="/Mydirect">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <small className="d-block mb-1 colors card-heading ims">
                              My Referral
                            </small>
                            <i className="fa fa-renren ims"></i>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="mb-0 pt-1 text-nowrap">
                                {dash.TotalReferral}
                              </h5>
                              <small className="text-muted1">Total</small>
                            </div>
                            <div className="col-4">
                              <div className="divider divider-vertical"></div>
                            </div>
                            <div
                              className="col-4 text-right"
                              style={{ textAlign: "right" }}
                            >
                              <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                                {dash.TotalActiveReferral}
                              </h5>
                              <small className="text-muted1">Active</small>
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent="75"
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link to="/NewDownline">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <small className="d-block mb-1 colors card-heading ims">
                              My Team
                            </small>
                            <i className="fa fa-users ims"></i>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="mb-0 pt-1 text-nowrap">
                                {" "}
                                {dash.TotalTeam}{" "}
                              </h5>
                              <small className="text-muted1">Total</small>
                            </div>
                            <div className="col-4">
                              <div className="divider divider-vertical"></div>
                            </div>
                            <div
                              className="col-4 text-end "
                              style={{ textAlign: "right" }}
                            >
                              <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                                {" "}
                                {dash.TotalActiveTeam}
                              </h5>
                              <small className="text-muted1">Active</small>
                            </div>
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent="75"
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      Welcome Bonus Staking
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.StakeToken}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <Link to="/DirectIncome">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Direct Bonus
                        </small>
                        <i className="fa fa-renren ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.DirectIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{ textAlign: "right" }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success iq-progress progress-1"
                          data-percent="75"
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/SuperDirectIncome">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Super Direct Bonus
                        </small>
                        <i className="fa fa-tachometer ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.SuperDirectIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-end "
                          style={{ textAlign: "right" }}
                        >
                          USD
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success iq-progress progress-1"
                          data-percent="75"
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/LevelIncome">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Team Building Bonus
                        </small>
                        <i className="fa fa-briefcase ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.LevelIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-end "
                          style={{ textAlign: "right" }}
                        >
                          USD
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success iq-progress progress-1"
                          data-percent="75"
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/RankAdvanceBonus">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Rank Advancement Bonus
                        </small>
                        <i className="fa fa-renren ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.RankAdvancementIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{ textAlign: "right" }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success iq-progress progress-1"
                          data-percent="75"
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div className="col-md-4">
                    <Link to="/MatrixDownline">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <small className="d-block mb-1 colors card-heading ims">
                              Matrix Downline
                            </small>
                            <i className="fa fa-renren ims"></i>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5 className="mb-0 pt-1 text-nowrap">
                                {dash.MatrixDownline}
                              </h5>
                            </div>
                            <div className="col-4">
                              <div className="divider divider-vertical"></div>
                            </div>
                        
                          </div>
                          <div className="iq-progress-bar mt-2">
                            <span
                              className="bg-success iq-progress progress-1"
                              data-percent="75"
                              style={{
                                transition: "width 2s ease 0s",
                                width: "75%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div> */}

              {/* <div className="col-md-4">
                <Link to="/DirectMatrixIncome">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Direct Matrix Bonus
                        </small>
                        <i className="fa fa-shield ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.MatrixDirectIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-md-4">
                <Link to="/MatrixIncome">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Matrix Level Bonus
                        </small>
                        <i className="fa fa-rocket ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.MatrixIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-md-4">
                <Link to="/MatrixCtoIncome">
                  <div className="card" style={{ height: "143px" }}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Matrix Cto Bonus
                        </small>
                        <i className="fa fa-rocket ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.MatrixCTOIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
               */}
              <div className="col-md-4">
                <Link to="/dashboard">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          IP Address
                        </small>
                        <i className="fa fa-television ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {IPAddress}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5> */}
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/TravelCtoBonus">
                  <div className="card" style={{ height: "143px" }}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Travel CTO Bonus
                        </small>
                        <i className="fa fa-television ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.TravelCtoIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4"style={{ display: "none" }}>
                <Link to="/PoolIncome">
                  <div className="card" style={{ height: "143px" }}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                          Welcome Bonus
                        </small>
                        <i className="fa fa-television ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.PoolIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/PoolIncome">
                  <div className="card" style={{ height: "143px" }}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading ims">
                        Matching Bonus
                        </small>
                        <i className="fa fa-television ims"></i>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {" "}
                            {dash.BinaryIncome}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="divider divider-vertical"></div>
                        </div>
                        <div
                          className="col-4 text-right"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>
                        </div>
                      </div>
                      <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-success2 iq-progress progress-1"
                          data-percent={75}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "75%",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div className="col-md-4">
                <Link to="/WithdrawalHistory">
                  <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between"><small className="d-block mb-1 colors card-heading ims">Total Withdrawal</small><i className="fa fa-shopping-bag ims"></i></div>
                            <div className="row">
                                <div className="col-4">
                                  <h5 className="mb-0 pt-1 text-nowrap">  {dash.WithDrawal_Amount}</h5>
                                 
                            </div>
                            
                            
                          </div>
                          <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-info iq-progress progress-1"
                          data-percent={85}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "85%",
                          }}
                        ></span>
                      </div>
                        </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-md-4">
                <Link to="/Withdrawal">
                <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between"><small className="d-block mb-1 colors card-heading ims">Net Balance</small><i className="fa fa-paw ims"></i></div>
                            <div className="row">
                                <div className="col-4">
                                  <h5 className="mb-0 pt-1 text-nowrap"> {dash.Net_Balance}</h5>
                                 
                            </div>
                            
                           
                          </div>
                          <div className="iq-progress-bar mt-2">
                        <span
                          className="bg-info iq-progress progress-1"
                          data-percent={85}
                          style={{
                            transition: "width 2s ease 0s",
                            width: "85%",
                          }}
                        ></span>
                      </div>
                        </div>
                  </div>

                
                </Link>
              </div> */}

              <div className="col-lg-4">
                <div className="card-block card-stretch card-height">
                  <div
                    className="card  card-header d-flex justify-content-between"
                    style={{ height: "143px" }}
                  >
                    <div className="wdg-links  mod_width">
                      <div className="wdg-label ims">Affiliate Link:</div>
                      <div className="copy_btn_set">
                        <div className="wdg-box bxset primary">
                          <input
                            type="text"
                            className="wdg-input-box"
                            id="myInput"
                            readOnly
                            ref={CopyAddressRef}
                            value={`${hostSiteUrl}/register?referrallink=${dash.userid}`}
                            style={{ fontSize: "2rem" }}
                          />
                          <div className="fast-msg-box" />
                        </div>
                        <div className="wdg-actions copy_btn_set2">
                          {/* <button type="button" onClick="myFunction()"> */}

                          <CopyToClipboard
                            text={`${hostSiteUrl}/register?referrallink=${dash.userid}`}
                            onCopy={() => setCopied(true)}
                          >
                            <button type="button">
                              <span
                                style={{ fontSize: 15 }}
                                // onClick="myFunction()"
                              >
                                {copied ? "Copied" : "Copy"}
                              </span>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="mb-2 mt-4">
                        <i className="text-white fa fa-facebook iconss"></i>
                        <i className="text-white fa fa-instagram iconss"></i>
                        <i className="text-white fa fa-telegram iconss"></i>
                        <i className="text-white fa fa-twitter iconss"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                        Today Registration
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.UserTodayRegistration}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                        Today Activation
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.UserTodayActivation}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                        Today Income
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.UserTodayIncome}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      Total SHATRI sold 
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.TotalSell}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      Total SHATRI Token
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.Total_Token}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      SHATRI Net Balance
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.Net_Token}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      Buy Token Fund
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {dash.Net_buyFunds}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      SHATRI Buy Rate
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {parseFloat(dash.BuyRate).toFixed(2)}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ height: "143px" }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <small className="d-block mb-1 colors card-heading ims">
                      SHATRI Sell Rate
                      </small>
                      <i className="fa fa-television ims"></i>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5 className="mb-0 pt-1 text-nowrap">
                          {" "}
                          {parseFloat(dash.SellRate).toFixed(2)}
                        </h5>
                      </div>
                      <div className="col-4">
                        <div className="divider divider-vertical"></div>
                      </div>
                      <div
                        className="col-4 text-right"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {/* <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                            USD
                          </h5>  */}
                      </div>
                    </div>
                    <div className="iq-progress-bar mt-2">
                      <span
                        className="bg-success2 iq-progress progress-1"
                        data-percent={75}
                        style={{
                          transition: "width 2s ease 0s",
                          width: "75%",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              
             
              <div className="col-md-6">
                <div className="">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Earning Reports</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bgbk">
                            <img src="Assets/images/logo.png"></img>
                            <h4 className="imgsss card-title1">
                              {dash.TOTAL_INCOME}{" "}
                            </h4>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <div className="">
                              <div className="d-flex gap-2 align-items-center">
                                <div className="badge rounded bg-label-primary  mts">
                                  <i className="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 className="mt-4 text-uppercase">
                                  Total Bonus
                                </h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5> {dash.TotalIncome}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-success w-30" />
                              </div>
                            </div>
                            <div className="">
                              <div className="d-flex gap-2 align-items-center">
                                <div className="badge rounded bg-label-primary  mts">
                                  <i className="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 className="mt-4 text-uppercase">
                                  Total Withdrawal
                                </h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5> {dash.WithDrawal_Amount}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-primary-1 w-80" />
                              </div>
                            </div>
                            <div className="">
                              <div className="d-flex gap-2 align-items-center">
                                <div className="badge rounded bg-label-primary  mts">
                                  <i className="fa fa-dollar"></i>
                                </div>
                                &nbsp;
                                <h6 className="mt-4 text-uppercase">
                                  Net Balance{" "}
                                </h6>
                              </div>
                              <div className="mt-2 mb-2">
                                <h5>{dash.Net_Balance}</h5>
                              </div>
                              <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-danger-1 w-65" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-12">
                            <div className="maline align-items-center">
                              <div className="">
                                  <div className="d-flex gap-2 align-items-center">
                                    <div className="badge rounded bg-label-primary  mts"><i className="fa fa-dollar"></i></div>&nbsp;
                                    <h6 className="mt-4 text-uppercase">Level Income </h6>
                                  </div>
                                  <div className="mt-2 mb-2">
                                    <h5>{dash.LEVEL_INCOME}</h5>
                                  </div>
                                  <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                <div className="progress-bar bg-danger-1 w-65" />
                              </div>
                              </div>
                             
                              <div className="">
                                  <div className="d-flex gap-2 align-items-center">
                                    <div className="badge rounded bg-label-primary  mts"><i className="fa fa-dollar"></i></div>&nbsp;
                                    <h6 className="mt-4 text-uppercase">Direct Income</h6>
                                  </div>
                                  <div className="mt-2 mb-2">
                                    <h5> {dash.ROI_INCOME}</h5>
                                  </div>
                                  <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                      <div className="progress-bar bg-primary-1 w-80" />
                                    </div>
                              </div>
                              <div className="">
                                  <div className="d-flex gap-2 align-items-center">
                                    <div className="badge rounded bg-label-primary  mts"><i className="fa fa-dollar"></i></div>&nbsp;
                                    <h6 className="mt-4 text-uppercase">Matrix Income</h6>
                                  </div>
                                  <div className="mt-2 mb-2">
                                    <h5> {dash.ROI_LEVEL_INCOME}</h5>
                                  </div>
                                  <div className="progress h-3 w-100 mt-1 flex-grow-1 mr-4">
                                    <div className="progress-bar bg-success w-30" />
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Link to="/News">
                  <div className="">
                    <div className="newshe card card-block card-stretch card-height">
                      <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                          <h4 className="card-title">News</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="">
                              <div className="">
                                <div className="d-flex gap-2 align-items-center">
                                  &nbsp;
                                  <h6 className="mt-4 text-uppercase">
                                    {news}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-12">
                <div className="">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Buy / Sell Rate Chart</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                        <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row space">
              {/* <div className="col-md-6">
                <div className="">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Latest News</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="card-body">
                        <marquee
                          width="100%"
                          direction="up"
                          height="265px;"
                          scrollamount={3}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {news &&
                                    news.map((item, index) => (
                                      <span style={{ fontSize: 26 }}>
                                        {item?.content}
                                      </span>
                                    ))}
                                
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </marquee>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row space" style={{ display: "none" }}>
              <div className="container">
                <div className="row row-deck" style={{ marginTop: 15 }}>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="prgs">
                      {dash.MaxIncome > 90 ? (
                        <img
                          src="/Assets/images/redscoter.png"
                          id="dynamicwidth"
                          className="wid"
                          style={{
                            marginLeft: `${dash.MaxIncome}%`,
                            width: "5%",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src="/Assets/images/greenscoter.png"
                          id="dynamicwidth"
                          className="wid"
                          style={{ marginLeft: `${dash.MaxIncome}%` }}
                          alt=""
                        />
                      )}

                      <div id="myProgress">
                        {dash.MaxIncome > 90 ? (
                          <div
                            id="myBar"
                            style={{
                              width: `${dash.MaxIncome}%`,
                              backgroundColor: "red",
                            }}
                          />
                        ) : (
                          <div
                            id="myBar"
                            style={{
                              width: `${dash.MaxIncome}%`,
                              backgroundColor: "green",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="text_color text-center mt-2"
                      style={{ fontSize: "medium" }}
                    >
                      <span>
                        You have earned total {dash.MaxIncome} % (
                        {dash.EarnAmount} USD) out of {dash.CapingPer} % (
                        {dash.tt} USD) of total investment ({dash.TotalInvest}
                        USD).
                      </span>
                    </div>
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n    .copy_btn_set{\n        display:flex;\n    }\n    .wdg-box {\n        position: relative;\n        background:#272727;\n        border-radius: 10px;\n        margin-bottom: 2px;\n        color: #fff;\n        width: 100%;\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        margin-right: 10px;\n        padding: 0px 2rem;\n    }\n    .wdg-links .wdg-input-box {\n        background: none;\n        border: 0;\n        color: #fff;\n        font: 300 1rem "Cairo",sans-serif;\n        width: 100%;\n    }\n    .wdg-actions {\n        display: flex;\n        /* justify-content: flex-end; */\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        border-radius: 10px;\n        background: #272727;\n        padding: 0px 15px;\n    }\n        .wdg-actions button {\n            color: #fff;\n            font: 500 1.2rem "Mukta Vaani",sans-serif;\n            text-transform: uppercase;\n            text-decoration: none;\n            background: none;\n            cursor: pointer;\n            border: 0;\n            display: inline-block;\n            padding-left: 0rem;\n            outline: none;\n        }\n',
                      }}
                    />
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                                .text_color {\n                                    color: #fff;\n                                    font-size: 18px;\n                                }\n\n                                #myProgress {\n                                    width: 100%;\n                                    background-color:#dcdfe8;\n                                }\n\n                                #myBar {\n                                    width: 1%;\n                                    height: 20px;\n                                }\n                            ",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        {/* Page end  */}
      </div>
      {/* Table Treeview JavaScript */}
      {/* Chart Custom JavaScript */}
      {/* Chart Custom JavaScript */}
      {/* app JavaScript */}
      <svg
        id="SvgjsSvg1001"
        width={2}
        height={0}
        xmlns="http://www.w3.org/2000/svg"
        // version="1.1"
        // xmlnsXlink="http://www.w3.org/1999/xlink"
        // xmlns:svgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002" />
        <polyline id="SvgjsPolyline1003" points="0,0" />
        <path id="SvgjsPath1004" d="M0 0 " />
      </svg>
    </>
  );
}
