import React from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.png";

export default function Topbar() {
  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
            <i className="fa fa-bars wrapper-menu" />
            <Link to="/dashboard" className="header-logo">
              {/* <img src={Logo} className="img-fluid rounded-normal" alt="logo" /> */}
              <img src="assets/images/logo.png" />
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}
