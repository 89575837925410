import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { Userid } from "../../Redux/slices/userAuth";
import { useSelector } from "react-redux";

export default function MatrixDownline() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [Level, setLevel] = useState("");
  const [Status, setStatus] = useState("");

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;


  let uid=useSelector(Userid)
  // console.log("Uid", uid);
  const MatrixDownlineAPI = async () => {
    try {
      let res = await API.post(`/matrixDownline`, {
        uid: uid,
        fdate: "",
        tdate: "",
        package: 0,
        level: Level,
      });
      res = res.data.data[0];
      setreferralApi([]);
      console.log("MatrixDownline", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          levelcount: item.leveltype,
          date1: `${item.edate}`,
          package: ` ${
            item.pp == "" ? " 0" : item.pp
          }`,
          top_update: `${item.ActivateDate} `,
          f_name:item?.f_name
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    MatrixDownlineAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "UserID", accessor: "uid" },
      { Header: "User Name", accessor: "f_name" },
      { Header: "Level", accessor: "levelcount" },
      { Header: "Date", accessor: "date1" },
      { Header: "Package", accessor: "package" },
      // { Header: 'Package Coin', accessor: 'tokenamount' },
    //  { Header: "Activation Date", accessor: "top_update" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Matrix Downline</h2>
          </div>
          <div className="row" style={{ marginLeft: 10 }}>
            {/* <div className="col-md-2">
              <label className="control-label">Select Level : </label>
            </div> */}
            <div className="col-md-3">
              <select
                className="form-control"
                id="level"
                value={Level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value="">Select Level</option>
                <option value={0}>All Level</option>
                <option value={1}>Level 1</option>
                <option value={2}>Level 2</option>
                <option value={3}>Level 3</option>
                <option value={4}>Level 4</option>
                <option value={5}>Level 5</option>
                <option value={6}>Level 6</option>
                <option value={7}>Level 7</option>
                <option value={8}>Level 8</option>
                <option value={9}>Level 9</option>
                <option value={10}>Level 10</option>
              </select>
            </div>
            {/* <div className="col-md-2">
              <label className="control-label">Choose Status : </label>
            </div> */}
            {/* <div className="col-md-3">
              <select
                className="form-control"
                id="status"
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value={2}>All</option>
                <option value={1}>Active</option>
                <option value={0}>In-Active</option>
              </select>
            </div> */}
            <div className="col-md-3">
              <div
                className="btn btnSubmit"
                onClick={() => MatrixDownlineAPI()}
              >
                Submit
              </div>
            </div>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
