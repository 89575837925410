import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
function Sell_Rate_Chart() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  let uid = useSelector(Userid);
  const LevelDetailsAPI = async () => {
    try {
      let res = await API.get(`/buysellrequestHistory`);
      res = res?.data.data;
      setreferralApi([]);
      console.log("ActivateHistory", res)
      let arr = [];
      res[0].forEach((item, index) => {
        arr.push({
          Number: item.row,
          Buy_Rate: item?.BuyRate,
          Sell_Rate: item?.sellRate,
          USD: item?.USD,
          Token_No: item?.TotalCoin,
          Remark: (
            <button
              className={`btn btn-theme1 text-white px-3 py-1 ${
                item.Remark == "Buy" ? "bgmain" : "sellmain"
              } shadow-none fs-12`}>
              {item?.Remark}
            </button>
          ),
          Txn: item?.Txn,
          Date: item?.dd,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };
  useEffect(() => {
    LevelDetailsAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Buy Rate", accessor: "Buy_Rate" },
      { Header: "Sell Rate", accessor: "Sell_Rate" },
      { Header: "USD", accessor: "USD" },      
      { Header: "Token No.", accessor: "Token_No" },
      { Header: "Remark", accessor: "Remark" },
      { Header: "Txn", accessor: "Txn" },
      { Header: "Date", accessor: "Date" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Buy / Sell Rate Chart</h2>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}

export default Sell_Rate_Chart;