import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import clicko from "../../Assets/images/click.png";
import { useNavigate } from "react-router-dom/dist";
import { loadWeb3 } from "../../API/contract";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import {
  ContractAddress_Main,
  Abi_Main,
  ContractAddress_USDT,
  Abi_USDT,
} from "../../API/ContractAddressBNB";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";

function Sell_Token() {
  const user = useSelector(Userid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [Amount, setAmount] = useState(0);
  const [NetWalletBal, setNetWalletBal] = useState(0);
  const [SelectType, setSelectType] = useState("Self Coin");
  const [dashData, setdashData] = useState([]);
  let [connected, setconnected] = useState("Wallet is locked");
  let [spiner, setspiner] = useState(false);
  const [TokenAmount, setTokenAmount] = useState("");
  const [Wallet_Address, setWallet_Address] = useState("");

  const SellWalletType = async (Selectedvalue) => {
    try {
      setSelectType(Selectedvalue);

      let responce = await API?.get(`dashboard?uid=${user}`);
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      console.log("getUserDetails", responce);

        setNetWalletBal(responce.Net_Token);
      
    } catch (e) {
       console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
     
    }
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${user}`);
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);
      setdashData(responce);
      setNetWalletBal(responce.Net_Token);
      setWallet_Address(responce.Accountaddress);
    } catch (e) {
      console.log("userDetails error", e);
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };


  const CalculateValue = async (val) => {
    const Selvalues = val.replace(/[^0-9]/gi, "").substring(0, 6);
    // const tvalue = parseFloat(Selvalues) + parseFloat(Amount);
    setTokenAmount(Selvalues);
    setAmount(Selvalues * dashData.SellRate);
  };


  const SellToken = async () => {
    if (user === undefined || user === "" || user === null) {
      navigate("/");
    }
    let acc = await loadWeb3();
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
    } else if (acc === "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
    } else {
      if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
        // if(1>0){
        if (Amount !== 0) {
          if (NetWalletBal < TokenAmount) {
            toast.error("Insufficient Sell Wallet Balance");
          } else {
            setspiner(true);

            try {
              let res = await API.post(
                "SellToken",
                {
                  uid: user,
                  tokenamount: TokenAmount,
                  remark: "SELF SELL",
                  selltype: "Self Coin",
                }
              );
              // console.log("sellcoin", res);

              if (res.data.data === "Sell Token Successfully") {
                toast.success("Sell Token Successfull");
                navigate("/dashboard");
                setspiner(false);
              } else {
                toast.error(res.data.data);
                setspiner(false);
              }

              setspiner(false);
            } catch (err) {
              console.log("error while calling fuction ", err);
              
              setspiner(false);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }

    // setloader(false)
  };

  useEffect(() => {
    WalletConnected();
    GetUserDetails();
  }, []);



  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ paddingBottom: "10px !important" }}
                >
                  <div className="avlbal">
                    <h2 className="modal-title" id="exampleModal3Label2">
                    Sell SHATRI Token
                    </h2>
                    <h5 className="modal-title" id="exampleModal3Label2">
                    Sell Wallet Balance : 
                      <span id="tokenbalance" style={{ color: "#fff" }}>
                      {NetWalletBal || 0}
                      </span>
                    </h5>
                    <h5 className="modal-title" id="exampleModal3Label2">
                    Sell Token Rate : 
                      <span
                        style={{ color: "#fff" }}
                        readOnly=""
                        id="getBalance"
                      >
                        {dashData.SellRate}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="popup_net">
                    <span
                      className="metamaskConnection"
                      style={{ color: "#fff" }}
                    >
                      {connected}
                    </span>
                    {/*-onclick number increase start*/}
                    <div className="addNum">
                      <div className="d-flex trxnumber">
                        <input
                          type="number"
                          placeholder="Token Amount"
                          id="amount"
                          className="form-control"
                          value={TokenAmount}
                              onChange={(e) => {
                                CalculateValue(
                                  DOMPurify.sanitize(e.target.value)
                                );
                              }}
                          style={{ width: "100%" }}
                        />
                      </div>
<br/>
<div className="d-flex trxnumber">
                        <input
                          type="number"
                          placeholder="Calculated USD Amount"
                          id="tokenvalue1"
                          className="form-control"
                          disabled={true}
                              defaultValue={Amount}
                              value={Amount}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    {/*-onclick number increase end*/}
                    <h5 className="tomenname" style={{ marginTop: "2rem" }} />
                    <div className="row select_network">
                      <div>
                        <div className="col-md-12 col-lg-offset-3">
                            <center>
                              <button href="#" onClick={() => SellToken()}>
                                <div className="text-center net_box">
                                  <img
                                    src={clicko}
                                    className="img-fluid hxnemg"
                                  />
                                  <h4>Sell Token</h4>
                                </div>
                              </button>
                              </center>
                           
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Sell_Token;