import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";

export default function Leveldetails() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [Level, setLevel] = useState("");
  const [Status, setStatus] = useState("");
  const [Rank, setRank] = useState(0);
  const [position, setPosition] = useState(0);
  const [dropdown, setdd] = useState([]);

  // let UserData = localStorage.getItem("userData");
  // let uid=JSON.parse(UserData);
  //  uid = uid.uid_output;

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  const LevelDetailsAPI = async () => {
    try {
      let res = await API.post(`/levelDetails`, {
        uid: uid,
        level: Level,
        //"status": Status
        fdate: "",
        tdate: "",
        rank: Rank,
        position: position,
      });
      res = res.data.data[0];
      setreferralApi([]);
      console.log("LevelDetails", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          levelcount: item.Leveltype,
          date1: `${item.edate}`,
          package: `$ ${
            item.ActivateAmount == "" ? " 0" : item.ActivateAmount
          }`,
          tokenamount: ` $ ${item.tokenamount} `,
          top_update: `${item.ActivateDate} `,
          sid: item?.sponser_id,
          rank: item?.rank_name,
          f_name: item?.f_name,
          pos: item?.pos,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  const DropDownAPI = async () => {
    try {
      let res = await API.get(`/RankList`);
      res = res.data.data[0];
      console.log("list", res);
      setdd(res);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    LevelDetailsAPI();
    DropDownAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "UserID", accessor: "uid" },
      { Header: "User Name", accessor: "f_name" },
      { Header: "SponsorID", accessor: "sid" },
      { Header: "Level", accessor: "levelcount" },
      { Header: "Registration Date", accessor: "date1" },
      { Header: "Package", accessor: "package" },
      { Header: "Rank", accessor: "rank" },
      { Header: "Position", accessor: "pos" },
      // { Header: 'Package Coin', accessor: 'tokenamount' },
      { Header: "Activation Date", accessor: "top_update" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Level Details</h2>
          </div>
          <div className="row d-flex justify-content-start gap-3 ms-10">
            {/* <div className="col-md-2">
              <label className="control-label">Select Level : </label>
            </div> */}
            <div className="col-md-3">
              <select
                className="form-control"
                id="level"
                value={Level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value="">Select Level</option>
                <option value={0}>All Level</option>
                <option value={1}>Level 1</option>
                <option value={2}>Level 2</option>
                <option value={3}>Level 3</option>
                <option value={4}>Level 4</option>
                <option value={5}>Level 5</option>
                <option value={6}>Level 6</option>
                <option value={7}>Level 7</option>
                <option value={8}>Level 8</option>
                <option value={9}>Level 9</option>
                <option value={10}>Level 10</option>
                <option value={11}>Level 11</option>
                <option value={12}>Level 12</option>
                <option value={13}>Level 13</option>
                <option value={14}>Level 14</option>
                <option value={15}>Level 15</option>
                <option value={16}>Level 16</option>
                <option value={17}>Level 17</option>
                <option value={18}>Level 18</option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                id="rank"
                value={Rank}
                onChange={(e) => setRank(e.target.value)}
              >
                {/* <option value="">Select Rank</option> */}
                <option value={0}>All Rank</option>
                {dropdown.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.rankname}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="col-md-2">
              <label className="control-label">Choose Position : </label>
            </div> */}
            <div className="col-md-3">
              <select
                className="form-control"
                id="position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option value="">Select Position</option>
                <option value={0}>All Position</option>
                <option value={1}>Left</option>
                <option value={2}>Right</option>
              </select>
            </div>
            {/* <div className="col-md-2">
              <label className="control-label">Choose Status : </label>
            </div> */}
            {/* <div className="col-md-3">
              <select
                className="form-control"
                id="status"
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value={2}>All</option>
                <option value={1}>Active</option>
                <option value={0}>In-Active</option>
              </select>
            </div> */}
            <div className="col-md-3">
              <div className="btn btnSubmit" onClick={() => LevelDetailsAPI()}>
                Submit
              </div>
            </div>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
