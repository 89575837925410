import React from "react";

export default function TreeHoverNode({ data }) {
  return (
    <div>
      <div class="popover__content">
        <div class="modsddal-areaksnc">
          <h6 style={{ borderBottom: "1px solid white" }}>
            Sponsor :{data?.Sponsor}
          </h6>
          <div className="contteett d-flex">
            <div style={{ borderRight: "1px solid white" }}>
              <p>Registration Date : {data?.registration_date}</p>
              <p>Package : {data.package}</p>
              <p>Total Left : {data?.total_left}</p>
              <p>Total Left Active : {data?.total_left_active}</p>
              <p>Total Left Business : {data?.left_business}</p>
              <p>Today Left Business : {data?.today_left_business}</p>
            </div>
            <div>
              <p>Activation Date : {data?.Activation_date}</p>
              <p>Package amount : {data?.package_amount}</p>
              <p>Total Right : {data?.total_right}</p>
              <p>Total Right Active : {data?.total_right_active}</p>
              <p>Total Right Business : {data?.right_business}</p>
              <p>Today Right Business : {data?.today_right_business}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
