import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";

export default function Myteam() {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [Level, setLevel] = useState(0);
  const [Status, setStatus] = useState(2);
  const [position, setPosition] = useState(0);

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;

  let uid = useSelector(Userid);
  console.log("Uid", uid);
  const LevelDetailsAPI = async () => {
    try {
      let res = await API.get(`/Myteam?uid=${uid}&position=${position}`);
      res = res.data.data;
      setreferralApi([]);
      console.log("LevelDetails", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          wallet: (
            <a
              href={`https://bscscan.com/address/${item.wallet}`}
              target="_blank"
            >
              {item.wallet}
            </a>
          ),
          date1: `${item.registrationDate}`,
          package: `$ ${
            item.ActivateAmount == "" ? " 0" : item.ActivateAmount
          }`,
          tokenamount: `$  ${item.tokenamount} `,
          top_update: `${item.ActivateDate} `,
          rank: item?.rank_name,
          f_name: item?.f_name,
          pos: item?.pos,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Level Details API", e);
    }
  };

  useEffect(() => {
    LevelDetailsAPI();
  }, []);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "UserID", accessor: "uid" },
      { Header: "User Name", accessor: "f_name" },
      //  { Header: "Wallet Address", accessor: "wallet" },
      { Header: "Registration Date", accessor: "date1" },
      { Header: "Package Amount", accessor: "package" },
      { Header: "Rank", accessor: "rank" },
      { Header: "Position", accessor: "pos" },
      // { Header: "Package Coin", accessor: "tokenamount" },
      { Header: "Activation Date", accessor: "top_update" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  ">
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">My Team</h2>
          </div>
          <div className="row d-flex justify-content-start gap-3 ms-10">
            <div className="col-md-2">
              <label className="control-label">Choose Position : </label>
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                id="position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option value="">Select Position</option>
                <option value={0}>All</option>
                <option value={1}>Left</option>
                <option value={2}>Right</option>
              </select>
            </div>
            <div className="col-md-3">
              <div className="btn btnSubmit" onClick={() => LevelDetailsAPI()}>
                Submit
              </div>
            </div>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div>
    </>
  );
}
