import React, { useEffect, useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";

export default function DlBusiness() {
  const [referralApi, setreferralApi] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [listPerpage, setlistPerpage] = useState(10)
  
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  
  // let UserData = localStorage.getItem("userData");
  // let uid=JSON.parse(UserData);
  //  uid = uid.uid_output;

  let uid=useSelector(Userid)
  // console.log("Uid", uid);

  const LevelDetailsAPI = async () => {
    try {
      let res = await API.get(`/directLegBusiness?uid=${uid}`)
      res = res.data.data[0]
      setreferralApi([])
      console.log("LevelDetails", res)
      let arr = []
      res.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          selfbv: item.selfbv,
          teambv: item.teambv,
          totalbusiness: item.gbv,
          f_name:item?.f_name
        })
      })

      setreferralApi(arr)
    } catch (e) {
      console.log("Level Details API", e)
    }
  }

  useEffect(() => {
    LevelDetailsAPI()
  }, [])
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: 'S.No', accessor: 'sr' },
      { Header: 'UserID', accessor: 'uid' },
      { Header: "User Name", accessor: "f_name" },
      { Header: 'Self Business', accessor: 'selfbv' },
      { Header: 'Team Business', accessor: 'teambv' },
      { Header: 'Total Business', accessor: 'totalbusiness' },

    ]
  })

  const indexOfLastPost = currentPage * listPerpage
  const indexOfFirstPage = indexOfLastPost - listPerpage
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost)


  return (
    <>
      <Sidebar />
      <Topbar />
      <div className="content-page  " >
        <div className="grid grid-1">
          <div className="section-heading">
            <h2 className="panel-title">Direct Leg Business</h2>
          </div>
          <div className="row" style={{ marginLeft: 10, display:'none' }}>
            {/* <div className="col-md-2">
              <label className="control-label">Select Level : </label>
            </div> */}
            <div className="col-md-3">
              <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)}></input>

            </div>
            {/* <div className="col-md-2">
              <label className="control-label">Choose Status : </label>
            </div> */}
            <div className="col-md-3">
              <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)}></input>
            </div>
            <div className="col-md-3">
              <div className="btn btnSubmit" onClick={() => LevelDetailsAPI()}>Submit</div>
            </div>
          </div>
          <div className="content-wrapper contpdset">
            <div className="grid grid-1">
              <div
                className="box box-default table-wrapper table-responsive"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >

                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                />

              </div>
            </div>
          </div>

          {/*-pagination start*/}
          {/*-pagination end*/}
        </div>
      </div >
    </>
  );
}
