import React from 'react'

function Header() {
  return (
<nav
      className="navbar navbar-expand-xl NavbarMain"
      aria-label="Offcanvas navbar large"
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            className="w-100 h-100"
            src="./assets2/image/logo/logo.png"
            alt="logo"
          />
        </a>
        <button
          className="navbar-toggler border-0 shadow-none text-white"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar2"
          aria-controls="offcanvasNavbar2"
        >
          <i className="fa fa-bars"style={{ fontSize: "25px", background:"red" }}></i>
          <span className="navbar-toggler-icon text-white" />
        </button>
        <div
          className="offcanvas offcanvas-end text-bg-dark"
          tabIndex={-1}
          id="offcanvasNavbar2"
          aria-labelledby="offcanvasNavbar2Label"
        >
          <div className="offcanvas-header">
            <div className="offcanvas-title" id="offcanvasNavbar2Label">
              <img
                className="w-100 h-100"
                src="./assets2/image/logo/logo.png"
                alt=""
              />
            </div>
            <button
              type="button"
              className="btn-close btn-close-white closebtn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-start flex-grow-1 ps-xl-5 align-items-xl-center gap-xl-3 gap-lg-2">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#Cryptocurrency">
                Cryptocurrency 
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#Defi">
                  Defi
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#Crowdfunding">
                 Shatri Token 
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#TronSecurity">
                Binance Security 
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#ImpactfulDefi">
                Impactful Defi
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/36t.pdf" target='_blank'>
                  Business Plan
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li> */}
            </ul>
            <div className="d-flex align-items-center gap-3">
            <a
                href="/TronLogIn"
                className="btn singupbtn px-xl-4 py-xl-2 px-4 py-3"
              >
                Tron Sign in
              </a>
              <a
                href="/LogIn"
                className="btn singupbtn px-xl-4 py-xl-2 px-4 py-3"
              >
                Sign in / Sign Up
              </a>
              <div className="conteryfleg">
                <img
                  className="w-100 h-100"
                  src="./assets2/image/header/Capa.svg"
                  alt="Capa"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
      )
}

export default Header