import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import "./IncomeWithdrawal.css";
import Sidebar from "../../Components/Dashboard/Sidebar";
import Topbar from "../../Components/Dashboard/Topbar";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import DOMPurify from "dompurify";
import { loadWeb3 } from "../../API/contract";

export default function Incomewithdrawal() {
  const [LAR_live, setLAR_live] = useState("");
  const [get_Value, setget_Value] = useState("");
  const [Connect, setConnect] = useState("Wallet is not connected..!..Wait...");
  const [spinner, setspinner] = useState(false);
  let [loading, setLoading] = useState(false);
  const [Amount, setAmount] = useState();
  const [Dash_Board_Array, setDash_Board_Array] = useState("");
  const [WalletAddress, setWalletAddress] = useState("");
  const [valueCoin, setvalueCoin] = useState(0);

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  // console.log("WalletAddress", WalletAddress);

  const getValue_BNB = async (e) => {
    // let value1=DOMPurify.sanitize(value.replace(/[^0-9]/gi,"").substring(0,7))
    // console.log("123",value1)
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
    let value = DOMPurify.sanitize(
      e.target.value.replace(/[^0-9]/gi, "").substring(0, 8)
    );
    try {
      // let Res = await API.get(`/getconvertValue?id=${value}`);
      // console.log("RES_BNB", Res);
      //setvalueBNB(Res.data.data[0].ethereum);
      setAmount(value);
      // setvalueCoin(Res.data.data[0].ethereum);
      setvalueCoin(value);
    } catch (e) {
      console.log("Error While Calling GetValue API", e);
    }
  };

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setDash_Board_Array(responce[0][0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

 

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();
      setWalletAddress(acc);
      if (acc == "No Wallet") {
        setConnect("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setConnect("Wrong Network");
      } else {
        setConnect("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Withdrawal_contract = async (e) => {
    e.preventDefault();
    try {
      let acc = await loadWeb3();
      // console.log("acc", acc);

      if (Dash_Board_Array.Accountaddress == acc) {
        // save data in db
        let saveResult = await API?.post("/withdrawal", {
          uid: uid,
          address: acc,
          amount: Amount,
          tokenvalue: Amount,
          txn: "",
        });
        console.log(saveResult);
        saveResult = saveResult.data.data;
        // setspinner(false);
        // alert(saveResult);
        if (saveResult == "SUCCESSFUL") {
          toast.success(saveResult);
        } else {
          toast.error(saveResult);
        }
      } else {
        toast.error("Address MissMatch");
        // setspinner(false);
      }
    } catch (e) {
      console.log("Error While Calling MultiToken ", e);
      // setspinner(false);
    }
  };

  useEffect(() => {
    Dashboard_API();
    WalletConnected();
  }, []);

  return (
    <>
      <Sidebar />
      <Topbar />
      <div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-6" style={{ top: "10rem" }}>
            <div className="modal-dialog">
              <div className="modal-content exchange_width">
                <div className="modal-header exchange_hd">
                  <h1>Withdrawal</h1>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="panel-body">
                      <span
                        className="metamaskConnection"
                        style={{ color: "#fff" }}
                      >
                        Note *{" "}
                        <span className="" style={{ color: "red" }}>
                          {" "}
                          Minimum Withdrawal is $10.You can withdraw upto $50
                          and the amount will be transferred to your wallet
                          immediately. Amount above $50 will appear in your
                          wallet within 24 Hours .
                        </span>
                      </span>
                      <br></br>
                      <br></br>
                      <span id="metamaskConnection">{Connect}</span>
                      <br />

                      <div className="row">
                        <div className="col-md-5">
                          <label>Wallet Net Balance</label>
                        </div>
                        <div className="col-md-6">
                          <label className="form-control">
                            {Dash_Board_Array.Net_Balance}
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-5">
                          <label>Enter Amount </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            id="amount"
                            placeholder="Enter Amount"
                            maxLength={10}
                            value={Amount}
                            onChange={(e) => {
                              getValue_BNB(e);
                            }}
                          />
                        </div>
                      </div>
                      <br />

                      {/* <div className="row">
                        <div className="col-md-5">
                          <label>Tron Amount </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control mb-20"
                            maxLength={10}
                            readOnly
                            placeholder="Calculated Tron Amount"
                            value={valueCoin}
                          />
                        </div>
                      </div> */}
                      <br></br>
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-4 col-md-offset-5 "
                            id="divsubmit"
                          >
                            <button
                              className="btn btn-success btn-lg"
                              // type="submit"
                              style={{ marginTop: 10 }}
                              id="btnother"
                              onClick={(e) => {
                                Withdrawal_contract(e);
                              }}
                            >
                              Withdrawal
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
