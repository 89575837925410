import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clicko from "../../Assets/images/click.png";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom/dist";
import Sidebar from "../../Components/Dashboard/Sidebar";
import Topbar from "../../Components/Dashboard/Topbar";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import { loadWeb3 } from "../../API/contract";
import TronWeb from "tronweb";
import { useDispatch, useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { updateAuth } from "../../Redux/slices/userAuth";
import Select from "react-select";

export default function Profile() {
  const [UsdAmount, setUsdAmount] = useState("0");
  const [TokenAmount, setTokenAmount] = useState("0");
  const [Bnb, setBnb] = useState("0");
  let [liveRate, setliveRate] = useState(0);
  let [amount, setamount] = useState(0);
  let [meticmultiplyablevalue, setmeticmultiplyablevalue] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [valueBNB, setvalueBNB] = useState(0);
  let [valueCoin, setvalueCoin] = useState(0);
  let [balanceUSDT, setbalanceUSDT] = useState(0);
  let [TrxBalance, setTrxBalance] = useState(0);
  let [spiner, setspiner] = useState(false);
  let [Sum, setSum] = useState(0);
  const [mainAccount, setMainAccout] = useState("");
  const [dash, setdataArray] = useState([]);
  const [loading, setloading] = useState(false);
  const [f_name, setf_name] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [CountryValue, setCountryValue] = useState("");

  const [selectedCountry, setSelectedCountry] = useState({});
  const [countries, setCountries] = useState([]);

  // console.log("selectedCountry", selectedCountry);
  // console.log("selectedCountry label", selectedCountry.label);
  // console.log("selectedCountry value", selectedCountry.value);

  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;
  // console.log("Uid", uid);

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setdataArray(responce[0][0]);
      // console.log("profileupdate", responce[0][0].profileupdate);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  const GetProfile_API = async () => {
    try {
      let responce = await API?.get(`GetUserProfile?uid=${uid}`);
      responce = responce.data.data[0][0];
      console.log("GetUserProfile", responce);

      setf_name(responce.f_name);
      setMobile(responce.mobile);
      setEmail(responce.email);
      setCountryValue(responce.countryname);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  
  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("Please Connect Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setMainAccout(acc);
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const handlef_name = async (value) => {
    setf_name(value.replace(/[^A-Z]/gi, ""));
  };
  const handleEmail = async (value) => {
    setEmail(value);
  };

  const handleMobile = async (value) => {
    setMobile(value.replace(/[^0-9]/gi, ""));
  };

  const UpdateProfile = async () => {
    let acc = mainAccount;
    if (!acc) {
      toast.error("No Wallet");
    } else if (acc != dash.Accountaddress) {
      toast.error("Wallet address and login miss match");
    } else {
      //setspiner(true);

      try {
        let res = await API.post(`/UpdateUserProfile`, {
          uid: uid,
          name: f_name,
          mobile: Mobile,
          email: Email,
          countrycode: selectedCountry.value,
          countryname: selectedCountry.label,
        });

        console.log("UpdateUserProfile", res);
        if (res.data.data.result == "Success") {
          toast.success("Profile updated successfully");
          window.location.reload();
        } else {
          toast.error(`${res.data.data.result}`);
        }

        // setspiner(false);
      } catch (err) {
        console.log("error while calling fuction profile", err);
        setspiner(false);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    Dashboard_API();
    GetProfile_API();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      walletConnected();
    }, 1000);
  }, []);

  // let countryName = selectedCountry.label;
  // let countryvalue = selectedCountry.value;
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
        // setcountryValue()
      });
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Change color based on selection
    }),
  };

  return (
    <>
     <Sidebar />
      <Topbar />
      
          <div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-6">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{ paddingBottom: "10px !important" }}
                    >
                      <div className="avlbal">
                        <h2 className="modal-title" id="exampleModal3Label2">
                          Profile
                        </h2>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="popup_net">
                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          Note *{" "}
                          <span className="" style={{ color: "red" }}>
                            {" "}
                            You can update your profile only for one time.
                          </span>
                        </span>
                        <br></br>

                        <span
                          className="metamaskConnection"
                          style={{ color: "#fff" }}
                        >
                          {connected}
                        </span>
                        {dash.profileupdate == 0 ? (
                          <>
                            <div className="addNum">
                              <div className="trxnumber">
                                <input
                                  type="text"
                                  className="form5"
                                  placeholder=""
                                  value={f_name}
                                  maxLength={20}
                                  onChange={(e) => {
                                    handlef_name(e.target.value);
                                  }}
                                />
                                <span>User Name</span>
                              </div>
                              <br />
                              <div className="trxnumber">
                                <input
                                  type="email"
                                  className="form5"
                                  placeholder=""
                                  value={Email}
                                  maxLength={30}
                                  onChange={(e) => {
                                    handleEmail(e.target.value);
                                  }}
                                />
                                <span>Email</span>
                              </div>
                              <br />
                              <div className="trxnumber">
                                <input
                                  type="text"
                                  className="form5"
                                  placeholder=""
                                  value={Mobile}
                                  maxLength={20}
                                  onChange={(e) => {
                                    handleMobile(e.target.value);
                                  }}
                                />
                                <span>Mobile No</span>
                              </div>
                              <br />

                              <div className="trxnumber">
                                <Select
                                  styles={{ width: "100%" }}
                                  id="countries"
                                  className="form5 wi"
                                  style={customStyles}
                                  options={countries}
                                  value={selectedCountry}
                                  onChange={(selectedOption) =>
                                    setSelectedCountry(selectedOption)
                                  }
                                />
                                <span>Select Country</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="addNum">
                              <div className="trxnumber">
                                <input
                                  type="text"
                                  className="form5"
                                  placeholder=""
                                  value={f_name}
                                  maxLength={20}
                                  readOnly
                                />
                                <span>User Name</span>
                              </div>
                              <br />
                              <div className="trxnumber">
                                <input
                                  type="email"
                                  className="form5"
                                  placeholder=""
                                  value={Email}
                                  maxLength={30}
                                  readOnly
                                />
                                <span>Email</span>
                              </div>
                              <br />
                              <div className="trxnumber">
                                <input
                                  type="text"
                                  className="form5"
                                  placeholder=""
                                  value={Mobile}
                                  maxLength={20}
                                  readOnly
                                />
                                <span>Mobile No</span>
                              </div>
                              <br />

                              <div className="trxnumber">
                                <input
                                  type="text"
                                  className="form5"
                                  placeholder=""
                                  value={CountryValue}
                                  maxLength={20}
                                  readOnly
                                />

                                <span>Select Country</span>
                              </div>
                            </div>
                          </>
                        )}

                        <h5
                          className="tomenname"
                          style={{ marginTop: "2rem" }}
                        />
                        <div className="row select_network">
                          <div>
                            <div className="col-md-12 col-lg-offset-3">
                              {dash.profileupdate == 0 ? (
                                <>
                                  <button
                                    href="#"
                                    onClick={() => UpdateProfile()}
                                  >
                                    <div className="text-center net_box ">
                                      <img
                                        src={clicko}
                                        className="img-fluid hxnemg"
                                      />
                                      <h4>Update Profile</h4>
                                    </div>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button href="#">
                                    <div className="text-center net_box ">
                                      <img
                                        src={clicko}
                                        className="img-fluid hxnemg"
                                      />
                                      <h4>Already Updated Profile</h4>
                                    </div>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
    </>
  );
}
