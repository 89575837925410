import React from "react";
import "./Dashboard.css";

const Comingsoon = () => {
  return (
    <>
      <div className="container">
        <div className="flex align-items-center">
          <div className="coming col-12">
            <h5>COMING SOON</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comingsoon;
