import React from "react";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../Redux/slices/userAuth";

export default function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleLogout() {
    dispatch(resetAuth());
    navigate("/");
  }
  return (
    <div className="iq-sidebar  sidebar-default ">
      <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
        <Link to="/dashboard" className="header-logo">
          {/* <img
            src={Logo}
            className="img-fluid rounded-normal light-logo"
            alt="logo"
          /> */}
          <img src="assets/images/logo.png" />
        </Link>
        <div className="iq-menu-bt-sidebar ml-0">
          <i className="fa fa-bars wrapper-menu" aria-hidden="true" />
        </div>
      </div>
      <div
        className="data-scrollbar"
        data-scroll={1}
        data-scrollbar="true"
        tabIndex={-1}
        style={{ overflow: "hidden", outline: "none" }}
      >
        <div className="scroll-content">
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="back_ground ">
                <Link to="/dashboard" className="svg-icon">
                  <svg
                    className="svg-icon"
                    id="p-dash1"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                    <line x1={12} y1="22.08" x2={12} y2={12} />
                  </svg>
                  <span className="ml-4">Dashboard</span>
                </Link>
              </li>
              <li className="back_ground ">
                <Link to="/profile" className="svg-icon">
                  {/* <svg
                    className="svg-icon"
                    id="p-dash1"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                    <line x1={12} y1="22.08" x2={12} y2={12} />
                  </svg> */}
                  <i className="fa fa-user-circle" />
                  <span className="ml-4">Profile</span>
                </Link>
              </li>
              <li className="back_ground">
                <a
                  href="#Deposit_Fund"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  
                  <i class="fa fa-key"></i>
                  <span className="ml-4">Deposit Fund</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>

                <ul
                  id="Deposit_Fund"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="back_ground">
                    <Link to="/Deposit">
                      <i class="fas fa-check-circle"></i>
                      <span>Deposit Fund</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/DepositHistory">
                      <i class="fas fa-history"></i>
                      <span>Deposit Fund History</span>
                    </Link>
                  </li>
                  
                </ul>
              </li>
              <li className="back_ground ">
                <Link to="/Sell_Rate_Chart" className="svg-icon">
                  <svg
                    className="svg-icon"
                    id="p-dash1"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                    <line x1={12} y1="22.08" x2={12} y2={12} />
                  </svg>
                  <span className="ml-4">Buy/Sell Rate Chart</span>
                </Link>
              </li>

              <li className="back_ground">
                <a
                  href="#Buy"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  
                  <i class="fa fa-key"></i>
                  <span className="ml-4">Buy SHATRI</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>

                <ul
                  id="Buy"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="back_ground">
                    <Link to="/Buy">
                      <i class="fas fa-check-circle"></i>
                      <span>Buy SHATRI Token</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/Buy_Token_Report">
                      <i class="fas fa-history"></i>
                      <span>Buy SHATRI Report</span>
                    </Link>
                  </li>
                  
                </ul>
              </li>

              <li className="back_ground">
                <a
                  href="#Sell"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  
                  <i class="fa fa-key"></i>
                  <span className="ml-4">Sell SHATRI</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>

                <ul
                  id="Sell"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="back_ground">
                    <Link to="/Sell_Token">
                      <i class="fas fa-check-circle"></i>
                      <span>Sell SHATRI Token</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/Sell_Token_Report">
                      <i class="fas fa-history"></i>
                      <span>Sell SHATRI Report</span>
                    </Link>
                  </li>
                  
                </ul>
              </li>

              <li className="back_ground">
                <a
                  href="#activation"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  {/* <svg
                    className="svg-icon"
                    id="p-dash6"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="4 14 10 14 10 20" />
                    <polyline points="20 10 14 10 14 4" />
                    <line x1={14} y1={10} x2={21} y2={3} />
                    <line x1={3} y1={21} x2={10} y2={14} />
                  </svg> */}
                  <i class="fa fa-key"></i>
                  <span className="ml-4">Activation</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>

                <ul
                  id="activation"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="back_ground">
                    <Link to="/activation">
                      <i class="fas fa-check-circle"></i>
                      <span>Activation</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/ActivateHistory">
                      <i class="fas fa-history"></i>
                      <span>Activation History</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/Stake_Token_Report">
                      <i class="fas fa-history"></i>
                      <span>SHATRI Stake History</span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/TravelActivation">
                      <i class="fas fa-route"></i>
                      <span>Thailand Travel Activation</span>
                    </Link>
                  </li>

                  <li className="back_ground">
                    <Link to="/TarvelActivateHistory">
                      <i class="fas fa-map-marked-alt"></i>

                      <span>Thailand Travel Activation History</span>
                    </Link>
                  </li>

                  {/* <li className="back_ground">
                    <Link to="/ActiveByFund">
                      <i class="fas fa-hand-holding-usd"></i>
                      <span>Matrix Activation </span>
                    </Link>
                  </li>
                  <li className="back_ground">
                    <Link to="/MatrixActivationHistory">
                      <i class="fas fa-clock"></i>
                      <span>Matrix Activation History</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="back_ground ">
                <a
                  href="#category"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  {/* <svg
                    className="svg-icon"
                    id="p-dash3"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                  </svg> */}
                  <i class="fa fa-users"></i>

                  <span className="ml-4">Team Detail</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="category"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/Mydirect">
                      <i className="fa fa-life-ring" />
                      <span>My Referral</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/NewDownline">
                      <i className="fa fa-tachometer" />
                      <span>My Team</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/LevelDetails">
                      <i class="fas fa-trophy"></i>
                      <span>Level Details</span>
                    </Link>
                  </li>
                  {/* <li className="">
                    <Link to="/MatrixDownline">
                      <i class="fas fa-sitemap"></i>
                      <span>Matrix Downline</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/MatrixTree">
                      <i class="fas fa-project-diagram"></i>
                      <span>Matrix Tree</span>
                    </Link>
                  </li> */}
                  <li className="">
                    <Link to="/MyRankDetails">
                      <i class="fas fa-list-ol"></i>
                      <span>Rank Details</span>
                    </Link>
                  </li>

                  <li className="">
                    <Link to="/mytree">
                      <i class="fas fa-network-wired"></i>
                      <span>Level Tree</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/DirectLegBusiness">
                      <i class="fas fa-arrow-right"></i>
                      <span>Direct Leg Business</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/TreeView">
                      <i class="fas fa-project-diagram"></i>
                      <span>Binary Tree</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="back_ground ">
                <a
                  href="#sale"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  {/* <svg
                    className="svg-icon"
                    id="p-dash4"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                    <path d="M22 12A10 10 0 0 0 12 2v10z" />
                  </svg> */}
                  <i class="fas fa-gift"></i>
                  <span className="ml-4">All Bonus</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="sale"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  {/* <li className="">
                    <Link to="/Roi_Level_Income">
                      <i className="fa fa-tasks" />
                      <span>Registration Cto Income</span>
                    </Link>
                  </li> */}
                  <li className="">
                    <Link to="/DirectIncome">
                      <i className="fa fa-suitcase" />
                      <span>Direct Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/SuperDirectIncome">
                      <i class="fas fa-star"></i>
                      <span>Super Direct Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/LevelIncome">
                      <i className="fa fa-tasks" />
                      <span>Team Building Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/RankAdvanceBonus">
                      <i class="fas fa-trophy"></i>
                      <span>Rank Advancement Bonus</span>
                    </Link>
                  </li>
                  {/* <li className="">
                    <Link to="/MatrixIncome">
                      <i class="fas fa-layer-group"></i>
                      <span>Matrix Level Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/DirectMatrixIncome">
                      <i class="fas fa-code"></i>
                      <span>Direct Matrix Bonus</span>
                    </Link>
                  </li>{" "}
                  <li className="">
                    <Link to="/MatrixCtoIncome">
                      <i class="fas fa-laptop-code"></i>
                      <span>Matrix Cto Bonus</span>
                    </Link>
                  </li> */}
                  <li className="">
                    <Link to="/TravelCtoBonus">
                      <i class="fas fa-trophy"></i>
                      <span>Travel CTO Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/PoolIncome">
                      <i class="fas fa-trophy"></i>
                      <span>Welcome Bonus</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/BinaryIncome">
                      <i class="fas fa-trophy"></i>
                      <span>Binary Bonus</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="back_ground ">
                <Link to="/News" className="svg-icon">
                  <i className="fa fa-user-circle" />
                  <span className="ml-4">News</span>
                </Link>
              </li>

              <li className="back_ground ">
                <a
                  href="#return"
                  className="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  {/* <svg
                    className="svg-icon"
                    id="p-dash6"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="4 14 10 14 10 20" />
                    <polyline points="20 10 14 10 14 4" />
                    <line x1={14} y1={10} x2={21} y2={3} />
                    <line x1={3} y1={21} x2={10} y2={14} />
                  </svg> */}
                  <i class="fas fa-money-bill-wave"></i>
                  <span className="ml-4">Withdrawal</span>
                  <svg
                    className="svg-icon iq-arrow-right arrow-active"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="10 15 15 20 20 15" />
                    <path d="M4 4h7a4 4 0 0 1 4 4v12" />
                  </svg>
                </a>
                <ul
                  id="return"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li className="">
                    <Link to="/Withdrawal">
                      <i className="fa fa-address-card" />
                      <span>Withdrawal</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/WithdrawalHistory">
                      <i className="fa fa-money" />
                      <span>Withdrawal History</span>
                    </Link>
                  </li>
                </ul>
              </li>
             
              <li className="back_ground">
                {/* <a
                      href="https://bscscan.com/address/0x8002E9A1b6920aAC06F214345cc81723377C82e0"
                      className=""
                      target="_blank"
                    > */}
                {/* <a href="/Contract_Address" className="" target="_blank">
                  <svg
                    className="svg-icon"
                    id="p-dash7"
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} y1={13} x2={8} y2={13} />
                    <line x1={16} y1={17} x2={8} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <span className="ml-4">Contract Address</span>
                </a> */}
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                <a href="/Wallet_Address" className="" target="_blank">
                  <i class="fas fa-wallet"></i>
                  <span className="ml-4">Wallet Address</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
              <li className="back_ground">
                <a href="#" className="" onClick={handleLogout}>
                  <i class="fas fa-sign-out-alt"></i>

                  <span className="ml-4">Logout</span>
                </a>
                <ul
                  id="reports"
                  className="iq-submenu collapse"
                  data-parent="#iq-sidebar-toggle"
                />
              </li>
            </ul>
          </nav>

          <div className="p-3" />
        </div>
        <div
          className="scrollbar-track scrollbar-track-x"
          style={{ display: "none" }}
        >
          <div
            className="scrollbar-thumb scrollbar-thumb-x"
            style={{ width: 260, transform: "translate3d(0px, 0px, 0px)" }}
          />
        </div>
        <div
          className="scrollbar-track scrollbar-track-y"
          style={{ display: "none" }}
        >
          <div
            className="scrollbar-thumb scrollbar-thumb-y"
            style={{ height: 619, transform: "translate3d(0px, 0px, 0px)" }}
          />
        </div>
      </div>
    </div>
  );
}
