import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div className="container">
        <div className="text-center" style={{ marginTop: "20rem" }}>
          <h1 style={{ color: "white" }}>404 page not found !</h1>
          <div className="d-grid gap-2 d-md-block">
            <Link
              className="btn btn-danger btn-lg"
              to="/"
              style={{ marginTop: "5rem" }}
            >
              click to go Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
