import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { API } from "../../API/Api";
import "../Dashboard/Dashboard.css";
import { useSelector } from "react-redux";
import { Userid } from "../../Redux/slices/userAuth";
import { toast } from "react-toastify";

function Wallet_Address() {
  // let UserData = localStorage.getItem("userData");
  // let uid = JSON.parse(UserData);
  // uid = uid.uid_output;

  let uid = useSelector(Userid);
  // console.log("Uid", uid);

  const CopyAddressRef = useRef(null);
  const CopyAddressHandler = () => {
    const text = CopyAddressRef.current.value;
    navigator.clipboard.writeText(text);
    toast.success('Copied')
  };

  const [dash, setdataArray] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`dashboard?uid=${uid}`);
      responce = responce.data.data;
      console.log("responceresponce", responce[0][0]);
      setdataArray(responce[0][0]);
    } catch (error) {
      console.log("Error While calling Referrer API", error);
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <div>
      <>
        <Sidebar />
        <Topbar />
        <div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-8" style={{ top: "10rem" }}>
              <div className=" card-header d-flex justify-content-between">
                <div className="wdg-links  mod_width">
                  <div className="wdg-label">Wallet Address:</div>
                  {/* <div className="copy_btn_set"style={{display:"flex"}}> */}
                  {/* <div className="wdg-box bxset primary"> */}
                  <div className="copy_btn_set">
                    <div className="wdg-box bxset primary">
                      <input
                        type="text"
                        className="wdg-input-box"
                        id="myInput"
                        readOnly=""
                        ref={CopyAddressRef}
                        value={`${dash.Accountaddress}`}
                        style={{ fontSize: "2rem" }}
                        width="100"
                      />
                    </div>
                    {/* <div className="fast-msg-box" /> */}
                    {/* </div> */}
                    <div className="wdg-actions copy_btn_set2">
                      {/* <button type="button" onClick="myFunction()"> */}
                      <button
                        className="btn "
                        type="button"
                        onClick={CopyAddressHandler}
                      >
                        <span
                          style={{ fontSize: 15 }}
                          // onClick="myFunction()"
                        >
                          Copy
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\n    .copy_btn_set{\n        display:flex;\n    }\n    .wdg-box {\n        position: relative;\n        background:#272727;\n        border-radius: 10px;\n        margin-bottom: 2px;\n        color: #fff;\n        width: 100%;\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        margin-right: 10px;\n        padding: 0px 2rem;\n    }\n    .wdg-links .wdg-input-box {\n        background: none;\n        border: 0;\n        color: #fff;\n        font: 300 1rem "Cairo",sans-serif;\n        width: 100%;\n    }\n    .wdg-actions {\n        display: flex;\n        /* justify-content: flex-end; */\n        border: 1px solid #307758;\n        /*box-shadow: 0 0 1rem #83daf4;*/\n        border-radius: 10px;\n        background: #272727;\n        padding: 0px 15px;\n    }\n        .wdg-actions button {\n            color: #fff;\n            font: 500 1.2rem "Mukta Vaani",sans-serif;\n            text-transform: uppercase;\n            text-decoration: none;\n            background: none;\n            cursor: pointer;\n            border: 0;\n            display: inline-block;\n            padding-left: 0rem;\n            outline: none;\n        }\n',
            }}
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n                                .text_color {\n                                    color: #fff;\n                                    font-size: 18px;\n                                }\n\n                                #myProgress {\n                                    width: 100%;\n                                    background-color:#dcdfe8;\n                                }\n\n                                #myBar {\n                                    width: 1%;\n                                    height: 20px;\n                                }\n                            ",
            }}
          />
        </div>
      </>
    </div>
  );
}

export default Wallet_Address;
