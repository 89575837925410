import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from "./slices/userAuth";

function PrivateRoutes() {
  const Auth = useSelector(isAuth);
  console.log("Private", Auth);

  return <>{Auth ? <Outlet /> : <Navigate to="/" />}</>;
}

export default PrivateRoutes;
