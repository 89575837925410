import React from "react";
// import Header from "./Components/Header/Header";
import Landing_Page from "./Components/Landing_page/Landing_Page";
// import Footer from "./Components/Footer/Footer";

export default function Home() {
  return (
    <div>
      {/* <Header /> */}
      <Landing_Page />
      {/* <Footer/> */}
    </div>
  );
}
