import React, { useEffect, useState } from "react";
import "./Landing_Style.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { resetAuth } from "../../Redux/slices/userAuth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
export default function Landing_Page() {
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const DefiPlatefrom = () => {
    naviagte("/DefiPlatefrom");
  };
  const InvestmentDefi = () => {
    naviagte("/InvestmentDefi");
  };
  const handleCrowdfunding = () => {
    naviagte("/Crowfunding");
  };
  const TronSecurity = () => {
    naviagte("/TronSecurity");
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const handleName = async (value) => {
    setName(value.replace(/[^A-Z ]/gi, ""));
  };
  const handleEmail = async (value) => {
    setEmail(value.replace(/[^A-Z@.0-9]/gi, ""));
  };
  const handleSubject = async (value) => {
    setSubject(value.replace(/[^A-Z 0-9]/gi, ""));
  };
  const handleMessage = async (value) => {
    setMessage(value.replace(/[^A-Z 0-9]/gi, ""));
  };
  const SubmitQuery = async () => {
    try {
      if (Name === "") {
        toast.error("Enter your Full name");
        return;
      }
      if (Email === "") {
        toast.error("Enter your Email Id");
        return;
      }
      if (Subject === "") {
        toast.error("Enter subject of your query");
        return;
      }
      if (Message === "") {
        toast.error("Enter your query");
        return;
      }
      console.log("SubmitQuery-->", "dfsdfsfsdfds");
      let res = await API.post(`/SubmitQuery`, {
        Name: Name,
        Email: Email,
        Subject: Subject,
        Message: Message,
      });

      console.log("SubmitQuery-->", res);
      if (res.data.data.Result == "Success") {
        toast.success("Query has been Submitted, We will respond you ASAP!!");
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
      } else {
        toast.error(`${res.data.data.Result}`);
      }
    } catch (err) {
      console.log("error while calling fuction profile", err);
    }
  };

  return (
    <div className="position-relative">
      {/*-------------------------------------------------- Header include Start----------------------------------- */}
      <Header />
      {/*--------------------------------------------------- Header include End-------------------------------------*/}
      <div className="container-fluid heroSection vh-10000 mb-3 mb-sm-4">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-6 col-md-8 h-100 d-flex flex-column align-items-start justify-content-center">
              <div className="heading">
                It’s Time to Capitalize on the DeFi Platform of the Future -
                Join 36t live{" "}
              </div>
              <p className="firstp">
                Navigate the Future of Finance and Exclusive Experiences with
                Blockchain Technology
              </p>

              <div className="d-flex">

              <Link to="/LogIn">
                <button className="btn btn-theme2 px-5 py-2 text-center text-white buttonmain">
                  Join Now
                </button>
              </Link>&nbsp;&nbsp;&nbsp;&nbsp;

              <a href="/36t.pdf" target="_blank">
                <button className="btn btn-theme2 px-5 py-2 text-center text-white buttonmain">
                  Explore
                </button>
              </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="justify-content-center">
                <center>
                <img src="./assets2/image/home/logo.png"/>
                </center>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------hero Section-------------------------------------------*/}
      <div className="bg-image-1">
        <div className="container-fluid py-lg-4 py-sm-3 py-3 position-relative travelSection">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="sectionheading1 pb-4 text-center">
                  36T Club emerges as a center of DeFi innovation, leveraging
                  the robust capabilities of the Binance Smart Chain blockchain.
                  We are not just a platform; we are a movement reshaping the
                  financial landscape, offering benefits of secure transactions,
                  community-driven initiatives, and transformative investment
                  opportunities. The 36T Club is where discerning investors
                  converge to explore the frontiers of decentralized finance.
                  It’s time to realize that the future is here, and it’s
                  brighter than ever!
                </div>
              </div>
              <div className="col-lg-12 order-2 order-lg-1">
                <div className="card border-0">
                  <div className="card-body p-3 p-md-5">
                    {/* <div className="card-heading">Read 36t Documents</div> */}
                    <p>
                      <b>Decentralized Rewards:- </b>
                      As a member, you will receive your rewards directly and
                      instantly, with the security and efficiency that
                      blockchain technology provides. Whether it's through
                      participation in community activities or other qualifying
                      actions, your rewards are computed and transferred to you,
                      maintaining the ethos of decentralization.
                    </p>
                    <p>
                      <b>Transparency:- </b>
                      Transparency is a core tenet of the 36T Club. By
                      leveraging blockchain technology, every transaction and
                      reward distribution is recorded on an immutable ledger
                      that is open for verification by any member at any time.
                    </p>
                    <p>
                      <b>Accessible to All:- </b>
                      By spending as low as 50 USDT, you can be a part of this
                      club. By supporting multiple payment methods and offering
                      a variety of membership packages, the 36T Club strives to
                      accommodate the needs of a global audience. This
                      inclusivity not only enriches the community but also
                      reinforces the club's vision of democratizing access to
                      networking opportunities and the potential financial
                      rewards that come with membership.
                    </p>

                    {/* <button className="btn btn-theme2 px-md-5 px-3 w-100 w-sm-auto py-2 text-center text-white buttonmain">
                      Business plan
                    </button> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-7 order-1 order-lg-2">
                <div className="computer_image p-4">
                  <img
                    className="w-100 h-100"
                    src="./assets2/image/home/computer.png"
                    alt="img2"
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="crossimage">
            <img
              className="w-100 h-100"
              src="./assets2/image/home/cros.svg"
              alt="img2"
            />
          </div>
        </div>
        <div
          className="container-fluid py-lg-4 py-sm-3 py-3 position-relative whyChoose"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="sectionheading pb-5 mb-sm-5">
                  Why Choose 36T Club
                </div>
              </div>
            </div>
            <div className="row g-4 cardsection p-sm-3 p-lg-4">
              <div className="col-lg-4">
                <div className="card border-0 py-3 h-100">
                  <div className="card-image p-sm-3 p-2 mx-auto">
                    <img
                      className="w-100 h-100"
                      src="./assets2/image/home/cardimage1.png"
                      alt=""
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-heading">
                      Technological Superiority
                    </div>
                    <div className="card-text">
                      Utilize the prowess of Binance Smart Chain high-speed
                      network for your transactions, enhancing efficiency and
                      reducing costs.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card border-0 py-3 h-100">
                  <div className="card-image p-sm-3 p-2 mx-auto">
                    <img
                      className="w-100 h-100"
                      src="./assets2/image/home/cardimage2.png"
                      alt=""
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-heading">Decentralized Benefits</div>
                    <div className="card-text">
                      Engage with a system that inherently offers more control
                      over your financial dealings, removing intermediaries and
                      fostering a direct connection to your investments.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card border-0 py-3 h-100">
                  <div className="card-image p-sm-3 p-2 mx-auto">
                    <img
                      className="w-100 h-100"
                      src="./assets2/image/home/cardimage3.png"
                      alt=""
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-heading">
                      Innovative Financial Models
                    </div>
                    <div className="card-text">
                      Our platform is at the vanguard of integrating DeFi
                      innovations with tangible real-world utility.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 networkSection">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="sectionheading pb-sm-5 pb-3">
                  The Binance Smart Chain Blockchain Advantage
                </div>
                <div className="sub_heading">
                  Binance Smart Chain experiencing a 299.2% increase in value
                  over the past 12 months is quite significant, especially in
                  the volatile world of cryptocurrencies. This performance
                  suggests a strong market confidence in Binance Smart Chain
                  underlying technology, its use cases, and the overall
                  ecosystem. The Binance Smart Chain blockchain platform was
                  created with the intention of decentralizing the web,
                  particularly focusing on the entertainment industry. It aims
                  to eliminate middlemen by allowing creators to publish, store,
                  and own data for free.
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-10 mx-auto">
                <div className="networkimage p-3">
                  <img
                    className="w-100 h-100"
                    src="./assets2/image/home/image-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 networkSection">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionheading pb-sm-5 pb-3">Migrate Tron Wallet Login to BSC </div>
              <div className="videobgs">
                <center>
                  <iframe
                    className="wii"
                    src="https://www.youtube.com/embed/PALpwro1-SQ?si=FvgF2mNC9LGk85kr"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 experiencesSection"
        id="Cryptocurrency"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionheading pb-5">
                Cryptocurrency and Binance Smart Chain: A Primer
              </div>
              {/* <div className="sub_heading pb-4 mb-5">
                36t live partners with top-notch travel agencies to offer
                you unparalleled travel experiences. Unlock these packages and
                receive 100% cash back based on the performance of the
                community. Here are some of our enticing travel packages:
              </div> */}
            </div>
          </div>
          <div className="row p-sm-4 cardsection g-4">
            <div className="col-lg-6 col-12 col-md-6">
              <div className="card border-0 card1">
                <div className="card-body">
                  <div className="card-heading text-white text-center fw-bold mb-2">
                    What is Cryptocurrency?
                  </div>
                  <div className="card-text text-center text-white">
                    Cryptocurrency represents a form of currency that is
                    digital, encrypted, and decentralized. Unlike traditional
                    fiat currencies, cryptocurrencies operate without the
                    central authority of banks or governments. They were
                    pioneered by the enigmatic Satoshi Nakamoto in 2009 with the
                    launch of Bitcoin, which introduced the world to blockchain
                    technology—a distributed ledger that maintains the integrity
                    of transaction data through decentralization and
                    cryptographic hashing
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-6">
              <div className="card border-0 card2">
                <div className="card-body">
                  <div className="card-heading text-white text-center fw-bold mb-2">
                    The Role of Binance Smart Chain in the Crypto Ecosystem
                  </div>
                  <div className="card-text text-center text-white">
                    Binance Smart Chain is a significant player within the
                    cryptocurrency landscape. Founded in 2017 by Justin Sun,
                    Binance Smart Chain aims to decentralize content on the web,
                    empowering creators and consumers alike. It's a
                    blockchain-based platform that has its own cryptocurrency
                    called TRX. Binance Smart Chain distinguishes itself with
                    high throughput, high scalability, and high availability for
                    all Decentralized Applications (DApps) in the ecosystem.
                    It's particularly popular for small transactions and online
                    content creators due to its efficient processing speeds and
                    low transaction fees.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-6">
              <div className="card border-0 card3">
                <div className="card-body">
                  <div className="card-heading text-center text-black fw-bold mb-2">
                    Crowdfunding in the Cryptosphere
                  </div>
                  <div className="card-text text-center text-black">
                    Crowdfunding is a method of raising capital through the
                    collective effort of friends, family, customers, and
                    individual investors. In the context of cryptocurrency, it
                    takes on a new dimension with the inclusion of global
                    investors participating through digital assets. It
                    democratizes the investment process in much the same way
                    that cryptocurrencies have democratized capital.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-6">
              <div className="card border-0 card4">
                <div className="card-body">
                  <div className="card-heading text-center text-black fw-bold mb-2">
                    Decentralized Finance (DeFi) Explained
                  </div>
                  <div className="card-text text-center text-black">
                    Decentralized Finance, or DeFi, is an umbrella term for
                    various financial applications in cryptocurrency or
                    blockchain geared toward disrupting financial
                    intermediaries. DeFi platforms allow people to lend or
                    borrow funds from others, speculate on price movements on a
                    range of assets using derivatives, trade cryptocurrencies,
                    insure against risks, and earn interest in savings-like
                    accounts. DeFi uses a layered architecture and highly
                    composable building blocks. It extends the blockchain’s
                    utility from a simple ledger to more complex financial use
                    cases, and Binance Smart Chain high-speed, low-cost
                    advantages make it a preferred blockchain for many DeFi
                    applications.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 experiencesSection">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionheading pb-5">
                The 36T Degree Support Club
              </div>
              <div className="sub_heading pb-4 mb-5">
                The 36T Degree Support Club is a forward-thinking platform that
                leverages the Binance Smart Chain Blockchain to facilitate a
                crowd funding system with immediate pay-out capabilities. It is
                engineered to serve as a decentralized solution that provides
                every participant with financial rewards upon investment.
              </div>
            </div>
          </div>
          <div className="row p-sm-4 cardsection g-4">
            <div className="col-lg-4 col-12 col-md-6">
              <div className="card border-0 card7">
                <div className="card-body">
                  <div className="card-heading text-center text-white fw-bold mb-2">
                    Mission Statement
                  </div>
                  <div className="card-text text-center text-white">
                    Our mission is to introduce a seamless, automated crowd
                    funding experience utilizing the power of smart contracts.
                    This initiative is designed to redefine the way investors
                    and entrepreneurs engage with crowd funding, making
                    transactions transparent, secure, and immediate.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="card border-0 card8">
                <div className="card-body">
                  <div className="card-heading text-center text-white fw-bold mb-2">
                    Value Proposition
                  </div>
                  <div className="card-text text-center text-white">
                    The club offers a direct financial incentive for membership
                    via an automated smart contract system. This system ensures
                    that payments are made directly to members' Binance Smart
                    Chain wallets without delay, negating the need for
                    intermediary oversight and enhancing transaction security.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-12">
              <div className="card border-0 card9">
                <div className="card-body">
                  <div className="card-heading text-center text-white fw-bold mb-2">
                    Decentralized Crypto Smart Contracts
                  </div>
                  <div className="card-text text-center text-white">
                    Smart contracts are at the heart of the club's
                    functionality. These contracts are predefined with
                    conditions that automatically execute based on the
                    achievement of set milestones within the crowd funding
                    process.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-image-2">
        <div
          className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 introvideoSection"
          id="Defi"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="sectionheading pb-5">
                  Binance Smart Chain Decentralized Finance (DeFi)
                </div>
                <div className="sub_heading">
                  <b>
                    Decentralized Finance: The Future with Binance Smart Chain
                  </b>
                  <br></br>
                  The intersection of DeFi and Binance Smart Chain ushers in a
                  new era for financial operations, leveraging blockchain's
                  decentralized nature to provide a more open, accessible, and
                  efficient market. Binance Smart Chain unique features augment
                  the DeFi landscape, offering multiple benefits that cater to
                  both burgeoning and established businesses.
                </div>
              </div>
            </div>
            <div className="row p-sm-4 p-2 mt-sm-5 mt-3 cardSection">
              <div className="col-md-12 px-1">
                <h4>1. High-Throughput Transactions: Efficiency at Scale</h4>
                <br></br>
                <p>
                  Binance Smart Chain high-throughput capacity ensures that
                  transactions are processed at an exceptionally rapid rate,
                  outperforming many other blockchain platforms. For businesses,
                  this means being able to support a high volume of transactions
                  without worrying about network congestion. This efficiency is
                  critical for enterprises that demand real-time transaction
                  processing and scalability as they grow.
                </p>

                <h4>2. Low Transaction Costs</h4>
                <br></br>
                <p>
                  Binance Smart Chain significantly reduces transaction costs, a
                  stark contrast to platforms with exorbitant gas fees. Lower
                  transaction costs directly translate into increased
                  cost-effectiveness for businesses, allowing them to allocate
                  more resources toward innovation and customer satisfaction
                  rather than operational expenses.
                </p>

                <h4>3. Inherently Decentralized Nature</h4>
                <br></br>
                <p>
                  Binance Smart Chain decentralized mechanism offers a security
                  advantage, as the network does not have a single point of
                  failure. This decentralization not only ensures greater
                  resilience against attacks but also provides businesses with a
                  level of autonomy over their transactions and contracts, free
                  from the control of any central authority.
                </p>

                <h4>4. Smart Contract Integration</h4>
                <br></br>
                <p>
                  The integration of smart contracts on Binance Smart Chain
                  platform facilitates automated business processes. Smart
                  contracts execute predetermined actions when their conditions
                  are met, eliminating the need for intermediaries and reducing
                  the scope for human error. This automation can be particularly
                  transformative for processes such as payments, settlements,
                  and contractual obligations.
                </p>

                <h4>5. Interoperable Platforms - Access to Broader Market</h4>
                <br></br>
                <p>
                  Binance Smart Chain is designed to be interoperable with
                  different blockchain platforms, which is invaluable for
                  businesses looking to tap into a broader market. It ensures
                  that DeFi services on Binance Smart Chain can communicate and
                  function seamlessly with other ecosystems, thereby broadening
                  the scope for collaboration and market penetration
                </p>

                <h4>
                  6. Sustainable Network Development: Long-Term Reliability
                </h4>
                <br></br>
                <p>
                  Binance Smart Chain commitment to sustainable network
                  development, through continuous upgrades and community-driven
                  governance, assures businesses of long-term reliability. This
                  ongoing development means that organizations can build their
                  DeFi services on a platform that is constantly evolving to
                  meet future demands.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 nowtoJoinSection"
          id="Crowdfunding"
        >
          <div className="container">
            <div className="row">
              <div className="sectionheading pb-3">
              SHATRI TOKEN
              </div>

              <div className="col-md-6 mx-auto">
                <div className="sub_heading1 py-3 pt-5">
                  <p>
                    <b>
                    Revolutionizing Finance with Blockchain Technology, where your money & digital assets work for you in the decentralized future of DeFi & FinTech.
                    </b>
                    <br></br><br></br>
                    Token Name - Shatri Token<br></br>
Total Supply - 210 Million.<br></br>
Binance Smart Chain<br></br><br></br>

The World’s first platform that offers Rewards & its Business Architecture helps investors Earn with Multiple Income Generating sources using Shatri Ecosystem.
                    <br></br>
                   
                  </p>
                </div>
                {/* <div className="">
                  <h5>Case Scenario Examples of Successful Tron-Based Crowdfunding </h5>
                  <p><b>Example A: Supply Chain Management dApp Development</b><br></br>

                    <b>Situation -</b> A software development company sought to create a decentralized application (dApp) that would disrupt traditional supply chain management for businesses. They aimed to introduce unparalleled transparency, real-time tracking, and smart contract-based settlements.
                      <br></br>
                     <b>Crowdfunding Campaign-</b> The company launched a crowdfunding initiative on the Tron blockchain, offering digital tokens to investors. These tokens represented both a stake in the project and the future utility in accessing the dApp's services.
                    <br></br>
                   <b> Outcome -</b> By achieving specific developmental milestones, the smart contract automatically released funds to the project. This mechanism ensured that investors were only committing funds as the company demonstrated real progress. As the dApp went live, it enabled businesses to optimize their supply chains, and token holders benefited from the increased adoption and token value.</p>
                <br></br>
                <p>
               <h6> Example B: B2B Financial Services Platform</h6>

                <b>Situation - </b>A fintech startup planned to create a platform that leverages blockchain technology to provide secure, decentralized financial services to other businesses.
                <br></br>
                <b>Crowdfunding Campaign -</b> Utilizing Tron's smart contracts, the startup offered a crowdfunding opportunity that was both secure and transparent. Contributors received proprietary tokens, which could later be used on the platform for services or traded.
                 <br></br>
                <b>Outcome -</b> The success of the campaign was twofold: it validated the demand for the product in the B2B space and provided the startup with the necessary capital. The smart contract framework allowed for immediate disbursement of funds upon reaching campaign goals, and the continuous transparency of transactions on the Tron ledger bolstered investor confidence.
                </p>
                </div> */}
                {/* <button
                  onClick={() => handleCrowdfunding()}
                  className="btn btn-theme2  px-sm-5 px-3 py-2 text-center text-white buttonmain mx-auto"
                >
                  Read More
                </button> */}

                {/* <button onClick={()=>handleCrowdfunding()}>Read More</button> */}
              </div>
              <div className="col-md-6">
                <div className="computer_image p-4">
                  <img
                    className="w-100 h-100"
                    src="./assets2/image/home/1.png"
                    alt="img2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 nowtoJoinSection"
        id="TronSecurity"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="sectionheading pb-3">
                Binance Smart Chain Security and Efficiency in Transactions
              </div>
              <p>
                In the blockchain world, security and efficiency are paramount.
                Binance Smart Chain smart contracts offer an optimal balance of
                both, fostering a trustworthy environment for all transaction
                types.
              </p>
            </div>
            <div className="col-md-6">
              <div className="computer_image p-4">
                <img
                  className="w-100 h-100"
                  src="./assets2/image/home/2.png"
                  alt="img2"
                />
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="sub_heading1 py-3">
                <p>
                  <b>How Does Binance Smart Chain Work?</b>
                  <br></br>
                  Binance Smart Chain functions as an advanced blockchain
                  platform designed to build a free, global digital content
                  entertainment system with distributed storage technology,
                  which allows easy and cost-effective sharing of digital
                  content. Here's how it works in a simplified manner:
                  <br></br>
                  <b>Binance Smart Chain Virtual Machine (TVM)</b>
                  <br></br>
                  The TVM is a lightweight, Turing-complete virtual machine
                  devised for the Binance Smart Chain ecosystem. Its purpose is
                  to provide developers with a blockchain system that is
                  efficient, convenient, and scalable. TVM allows for the
                  creation of smart contracts and decentralized applications
                  (DApps) using the TRC-20 token standard, which is compatible
                  with Ethereum's ERC-20 tokens. Binance Smart Chain Three-Layer
                  Architecture
                </p>
              </div>

              <button
                onClick={() => TronSecurity()}
                className="btn btn-theme2  px-sm-5 px-3 py-2 text-center text-white buttonmain mx-auto"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 nowtoJoinSection"
        id="ImpactfulDefi"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="sectionheading pb-3">
                How to Become a Part of an Impactful De-Fi Platform
              </div>
              <p>
                The Binance Smart Chain blockchain has established itself as a
                robust platform for decentralized applications and smart
                contracts, making it an enticing ecosystem for entrepreneurs and
                investors alike. The 36T Degree Support Club harnesses the power
                of Binance Smart Chain to facilitate a revolutionary
                crowdfunding model that rewards its participants promptly and
                transparently. Here’s how you can embark on this journey by
                setting up a Binance Smart Chain wallet and joining the club.
              </p>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="sub_heading1 py-3">
                <p>
                  <b>Understanding Binance Smart Chain Wallet</b>
                  <br></br>
                  Binance Smart Chain is the official wallet app of the Binance
                  Smart Chain network, a necessary tool for anyone interacting
                  with the Binance Smart Chain ecosystem. It's a gateway that
                  connects users to the decentralized web, allowing for secure
                  storage of TRX—the native cryptocurrency of Binance Smart
                  Chain and other tokens built on the Binance Smart Chain
                  blockchain. By having a Binance Smart Chain wallet, you can
                  participate in smart contract-based crowdfunding opportunities
                  with ease and assurance.
                  <br></br>
                  <b>Downloading and Installing Binance Smart Chain</b>
                  <br></br>
                  To begin, download the Binance Smart Chain wallet, which is
                  available as a mobile app for iOS and Android users and as a
                  browser extension for Chrome users. Download the version
                  compatible with your device from the official Binance Smart
                  Chain website or reputable app stores to ensure security.
                  Installation is straightforward—follow the on-screen
                  instructions, and the app or extension will be ready to use
                  within minutes.
                </p>
              </div>

              <button
                onClick={() => DefiPlatefrom()}
                className="btn btn-theme2  px-sm-5 px-3 py-2 text-center text-white buttonmain mx-auto"
              >
                Read More
              </button>

              {/* <button onClick={()=>handleCrowdfunding()}>Read More</button> */}
            </div>
            <div className="col-md-6">
              <div className="computer_image p-4">
                <img
                  className="w-100 h-100"
                  src="./assets2/image/home/3.png"
                  alt="img2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 ContractSection">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="heading fw-bold text-white text-center">
                Contract Address
              </div>
            </div>
            <div className="col-md-10 mx-auto text-center mt-4">
              <div className="row mx-0 g-3">
                <div className="col-sm">
                  <input
                    className="form-control"
                    type="text"
                    name=""
                    id=""
                    required=""
                    defaultValue="chgsdabchFCDHTGCsvhgsgcvt234ty234v"
                  />
                </div>
                <div className="col-sm-auto">
                  <button className="btn flex-column copybtn align-items-center justify-content-center text-black d-flex">
                    <span className="">
                      <img
                        className="w-100 h-100"
                        src="./assets2/image/home/copy.svg"
                        alt=""
                      />
                    </span>
                    copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 learningCenterSection">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionheading mb-sm-3">
                Investment Opportunities Through DeFi
              </div>
            </div>
          </div>
          <div className="row p-sm-3 p-2 lerncard g-4 mt-3">
            <div className="col-md-6 col-lg-6">
              <a href="/" className="card1 text-decoration-none border-0 mb-3">
                <div className="card-image1">
                  <img
                    className="h-100 w-100"
                    src="./assets2/image/home/5.png"
                    alt="youtube"
                  />
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-6">
              <p>
                The emergence of decentralized finance (DeFi) on blockchain
                platforms presents a compelling case for investors, particularly
                those within B2B SaaS and FinTech sectors. With real-life
                applications already reshaping industries, the travel sector
                provides an illustrative precedent. Companies like Winding Tree
                and Webjet exemplify how blockchain reduces costs and
                inefficiencies, suggesting parallel advantages for B2B services.
                Investors can extrapolate these successes to imagine a DeFi
                landscape where similar cost savings and efficiency gains are
                achievable in B2B transactions and financial services.
              </p>
              <p>
                For instance, Winding Tree's disintermediation of travel booking
                demonstrates the potential for similar platforms to disrupt B2B
                SaaS gatekeepers, reducing fees and increasing transparency.
                Moreover, Webjet's use of blockchain to mitigate booking
                inaccuracies and ensure data integrity presents a model for
                improving transactional trust in B2B services, an area where
                FinTech is poised to benefit significantly.
              </p>
              <button
                onClick={() => InvestmentDefi()}
                className="btn btn-theme2  px-sm-5 px-3 py-2 text-center text-white buttonmain mx-auto"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 faqSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="sectionheading mb-sm-2">
                <img src="./assets2/image/home/travel.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 faqSection"
        id="faq"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionheading mb-sm-2">
                Frequently Asked Questions
              </div>
              <div className="sub_heading mb-2">Still have questions?s</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button shadow-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>Q1.</span>What is 36T Club?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The 36T Club is a global decentralized community
                      leveraging the Binance Smart Chain blockchain to offer
                      members unique networking opportunities, exclusive travel
                      experiences, and automated reward distribution.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingTwo"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>Q2.</span>How do decentralized rewards work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Decentralized rewards are facilitated by smart contracts,
                      which automatically execute and distribute rewards to
                      members based on their participation and contributions to
                      the community, ensuring a fair and transparent reward
                      system.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingThree"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>Q3.</span>Is the 36T Club accessible to anyone?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, the 36T Club is designed to be accessible to all,
                      regardless of geographic location or background. We offer
                      various membership packages to suit different needs and
                      ensure that the club is as inclusive as possible.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingFour"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span>Q4.</span>Are transactions within 36T Club secure?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      Yes, transactions are secured by the robust Binance Smart
                      Chain blockchain protocol, ensuring safety, integrity, and
                      reliability of all financial movements within the club.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingFour"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span>Q5.</span>What makes the 36T Club transparent?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      Transparency is achieved through the use of blockchain
                      technology where all transactions and distributions are
                      recorded on a public ledger, visible and verifiable by any
                      member at any time.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingFive"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <span>Q6.</span>How can I join the 36T Club?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                    Click Register Button in the Menu Bar, Connect your BSC based web3 wallet , after the wallet is connected, click register . A Pop up will appear , type your sponsor ID and select position left or right , then click register. Congratulations, you have successfully register your account in 360 Degree Club.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingSix"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <span>Q7.</span>How to buy shatri token?

                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                       Register and add funds , click on the Buy token option in the dashboard menu,  buy your desired amount of shatri token.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingEight"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      <span>Q8.</span>Can I earn from the 36T Club without
                      traveling?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      Absolutely! While travel is a key component of our club,
                      members can earn rewards through various other
                      community-driven activities and networking opportunities.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingNine"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      <span>Q9.</span>How are rewards calculated?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      Rewards are calculated based on a transparent algorithm
                      that considers your level of participation and the overall
                      performance of the community.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-item border-0 shadow-none"
                    id="headingTen"
                  >
                    <button
                      className="accordion-button shadow-none collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      <span>Q10.</span>Can I upgrade my membership?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      Members have the flexibility to upgrade their membership
                      package at any time to enjoy additional benefits and
                      higher reward potential.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-image-3">
        <div
          className="container-fluid py-lg-4 py-sm-3 py-3 mt-sm-4 contactusSection"
          id="contact"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="sectionheading mb-sm-2">Contact Us</div>
                <div className="sub_heading">Get in touch</div>
              </div>
            </div>
            <form
              method="post"
              className="row cardSection px-sm-3 px-2 py-sm-4 py-3 gy-4"
            >
              <div className="col-12 mt-2 mt-sm-0">
                <input
                  style={{color:"white"}}
                  className="form-control form-control-lg"
                  type="text"
                  maxLength="50"
                  placeholder="Your Name"
                  onChange={(e) => {
                    handleName(e.target.value);
                  }}
                  value={Name}
                />
              </div>
              <div className="col-12">
                <input
                  style={{color:"white"}}
                  maxLength="100"
                  className="form-control form-control-lg"
                  type="email"
                  placeholder="Your E-Mail"
                  onChange={(e) => {
                    handleEmail(e.target.value);
                  }}
                  value={Email}
                />
              </div>
              <div className="col-12">
                <input
                  style={{color:"white"}}
                  maxLength="200"
                  className="form-control form-control-lg"
                  placeholder="Subject"
                  onChange={(e) => {
                    handleSubject(e.target.value);
                  }}
                  value={Subject}
                />
              </div>
              <div className="col-12">
                <textarea
                  style={{color:"white"}}
                  maxLength="500"
                  placeholder="Message"
                  className="form-control"
                  rows={6}
                  onChange={(e) => {
                    handleMessage(e.target.value);
                  }}
                  value={Message}
                />
              </div>
            </form>
            <div className="row mt-3">
              <div className="col-12 justify-content-center justify-content-md-start d-flex">
                <button
                  className="formbtn btn py-2 px-4 fw-bold"
                  onClick={() => {
                    SubmitQuery();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
